import { IChartItem } from '@/types/feedback.type';

export const transformDataChart = (data: IChartItem[]) => {
  return data && data.length > 0
    ? data.map((item) => ({
        date: item.date,
        like: item.reactions.like,
        dislike: item.reactions.dislike,
        other: item.reactions.other,
      }))
    : [];
};
