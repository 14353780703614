import dayjs from 'dayjs';

export const formatDate = (value: string) => {
  const date = dayjs(value);
  if (date.isValid()) {
    return date.format('YYYY/MM/DD');
  } else {
    return value;
  }
};

export const getUnixTime = (date: string | dayjs.Dayjs | null) => {
  if (!date || date === 'No Deadline') {
    return Infinity; // Set invalid dates to Infinity to move them to the end
  }
  const parsedDate = dayjs(date);
  return parsedDate.isValid() ? parsedDate.unix() : Infinity;
};

export const extractDateFromHTML = (
  value: string | dayjs.Dayjs | null,
): number => {
  console.log(value);
  if (!value) return Infinity;

  let date: string | null = null;

  if (typeof value === 'string') {
    if (/<.*?>/.test(value)) {
      const match = value.match(/<span[^>]*>(.*?)<\/span>/);
      date = match ? match[1].trim() : null;
    } else {
      date = value.trim();
    }
  } else if (dayjs.isDayjs(value)) {
    return value.unix();
  }

  const parsedDate = dayjs(date);
  return parsedDate.isValid() ? parsedDate.unix() : Infinity;
};
