import { IRiskRes } from '@/types/risk.type';
import { axiosInstance } from '@/utils/axios';

export const getRiskListService = async (
  project_id: string,
): Promise<IRiskRes> => {
  const response = await axiosInstance.get(
    `/project/${project_id}/risk/list_risk`,
  );
  return response.data;
};
