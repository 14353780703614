import styled from 'styled-components';

export const ProjectItemWrap = styled.div`
  background-color: #e0e6e4;
  color: #000000;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
`;
export const ItemTitle = styled.h3`
  margin: 0;
  font-size: 20px;
  font-weight: 600;
`;
