import themeConfigs from '@/config/themeConfigs';
import { Layout } from 'antd';
import styled from 'styled-components';
const { Header } = Layout;
export const PageWrap = styled(Layout)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
export const CustomHeader = styled(Header)`
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 0;
  line-height: 56px;
  background: ${themeConfigs.primaryColor};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  position: sticky;
  top: 0;
  .headerLeft {
    display: flex;
    align-items: center;
    height: 100%;

    .logo {
      display: flex;
      align-items: center;
      min-width: 280px;
      padding: 0 24px;
      color: #333;
      font-weight: bold;
      font-size: 18px;

      img {
        width: 30px;
        margin-right: 16px;
      }
    }

    .menus {
      width: 600px;
      height: 100%;
      border: none;

      & > li {
        top: 0;
        height: 100%;
        margin: 0;
      }

      &:global(.ant-menu-horizontal > .ant-menu-item a::before) {
        bottom: 0;
      }
    }
  }

  .headerRight {
    display: flex;
    align-items: center;
    padding-right: 24px;

    .userInfo {
      display: flex;
      align-items: center;
      margin-left: 24px;
    }

    .userName {
      margin-left: 10px;
      color: white !important;
      font-size: 16px;
      text-align: center;
    }
  }
  .titleLogo {
    color: #ffffff;
  }
  .searchInput {
    background: rgba(254, 240, 240, 0.3) !important;
    border-radius: 12px;
    width: 550px;
    height: 36px;
    color: #ffffff;
    &.ant-input-affix-wrapper > input.ant-input {
      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }
`;

export const CustomLeft = styled.div`
  color: #fff;
  font-style: normal;
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
`;

export const CustomRight = styled.div`
  color: #fff;
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const Navbar = styled.ul<{ $isMobile?: boolean }>`
  display: flex;
  list-style: none;
  padding: ${(props) => (props.$isMobile ? '0' : '0 60px')};
  margin: 0 -16px;
  flex-direction: ${(props) => (props.$isMobile ? 'column' : 'row')};
  li {
    padding: ${(props) => (props.$isMobile ? '8px' : ' 0 16px')};

    a {
      color: ${(props) =>
        props.$isMobile
          ? themeConfigs.primaryColor
          : 'rgba(255, 255, 255, 0.65)'};
      font-weight: 700;
      text-decoration: none;
      &[aria-current='page'] {
        /* Add your active link styles here */
        color: ${(props) =>
          props.$isMobile
            ? themeConfigs.primaryColor
            : 'rgba(255, 255, 255, 1)'};
        font-weight: 700;
        text-decoration: ${(props) => (props.$isMobile ? 'underline' : 'none')};
      }
    }
    &:hover {
      a {
        color: ${(props) =>
          props.$isMobile
            ? themeConfigs.primaryColor
            : 'rgba(255, 255, 255, 1)'};
        font-weight: 700;
        text-decoration: ${(props) => (props.$isMobile ? 'underline' : 'none')};
      }
    }
  }
`;

export const CustomContent = styled(Layout)`
  /* flex: 1;
  padding-bottom: 100px; */
  /* padding: 0 142px 142px 142px; */
`;
