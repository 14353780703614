import React from 'react';
import { AppRouter } from './router/AppRouter';

function AppWrap() {
  return (
    <>
      <AppRouter />
    </>
  );
}

export default AppWrap;
