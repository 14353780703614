import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { LoginPage } from '@/pages/LoginPage/LoginPage';
import useAuthStore from '@/store/authStore';
import PrivateRoute from './PrivateRoute';
import { NotFound } from '@/pages/NoteFound';
import { DocumentsPage } from '@/pages/DocumentsPage';
import { DependencyListPage } from '@/pages/DependencyListPage';
import { ConfirmedDependencyPage } from '@/pages/ConfirmedDependencyPage';
import { ProjectsPage } from '@/pages/ProjectsPage';
import { RisksPage } from '@/pages/RisksPage';
import { ConflictConfirmationPage } from '@/pages/ConflictConfirmationPage';
import { MainLayout } from '@/layout/MainLayout';
import { OverviewPage } from '@/pages/OverviewPage';
import { FeedbackPage } from '@/pages/FeedbackPage';
import { AllFeedbackPage } from '@/pages/AllFeedbackPage';
import { EvaluatePage } from '@/pages/EvaluatePage';
import ProjectDetailPage from '@/pages/ProjectDetailPage';

export const AppRouter = () => {
  const { user } = useAuthStore();
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/projects" replace />} />

        {/* Protected Routes */}
        <Route path="projects" element={<PrivateRoute />}>
          <Route index element={<ProjectsPage />} />

          {/* Project Route with :project_id */}
          <Route path=":project_id">
            {/* Show ProjectPage only for the main :project_id path */}
            <Route index element={<ProjectDetailPage />} />{' '}
            {/* Only renders on /projects/:project_id */}
            {/* Nested Routes under :project_id */}
            <Route element={<MainLayout />}>
              <Route path="overview" element={<OverviewPage />} />
              <Route path="documents" element={<DocumentsPage />} />
              <Route path="risks" element={<RisksPage />} />
              <Route path="evaluate" element={<PrivateRoute />}>
                <Route index element={<EvaluatePage />} />
              </Route>
              <Route path="dependency">
                <Route
                  path="dependency-list"
                  element={<DependencyListPage />}
                />
                <Route
                  path="confirmed-dependency"
                  element={<ConfirmedDependencyPage />}
                />
                <Route
                  path="conflict-confirmation"
                  element={<ConflictConfirmationPage />}
                />
              </Route>
              <Route path="feedback" element={<FeedbackPage />} />
            </Route>
          </Route>
        </Route>

        {/* Separate Route for All Feedback */}
        <Route path="all-feedback" element={<PrivateRoute />}>
          <Route element={<MainLayout />}>
            <Route index element={<AllFeedbackPage />} />
          </Route>
        </Route>

        {/* Login Route */}
        <Route
          path="login"
          element={user ? <Navigate to="/" replace /> : <LoginPage />}
        />

        {/* Not Found Route */}
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </Router>
  );
};
