import themeConfigs from '@/config/themeConfigs';
import { IChartItemTransform } from '@/types/feedback.type';
import { dateFormatterForChart } from '@/utils/dateFormatterForChart';
import { Space, Tag, Tooltip as TooltipAnt } from 'antd';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export const ChartFeedBackComponent = ({
  title,
  chartData,
}: {
  title: string;
  chartData: IChartItemTransform[];
}) => {
  const totalCounts = chartData.reduce(
    (acc, entry) => {
      acc.like += entry.like;
      acc.dislike += entry.dislike;
      acc.other += entry.other;
      return acc;
    },
    { like: 0, dislike: 0, other: 0 },
  );
  return (
    <div style={{ marginBottom: '50px' }}>
      <h2>
        <Space align="center">
          <span>{title}</span>
          <TooltipAnt
            placement="top"
            title={
              <Space direction="vertical">
                <span>Like: {totalCounts.like}</span>
                <span>Dislike: {totalCounts.dislike}</span>
                <span>Other: {totalCounts.other}</span>
              </Space>
            }
          >
            <Tag
              color={themeConfigs.primaryColor}
              style={{ verticalAlign: 'middle' }}
            >
              {totalCounts.like + totalCounts.dislike + totalCounts.other}
            </Tag>
          </TooltipAnt>
        </Space>
      </h2>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={chartData}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tickFormatter={dateFormatterForChart} />
          <YAxis allowDecimals={false} />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="like" stroke="#8884d8" />
          <Line type="monotone" dataKey="dislike" stroke="#82ca9d" />
          <Line type="monotone" dataKey="other" stroke="#ffc658" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
