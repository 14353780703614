import { TitleGroup } from '@/components/TitleGroup';
import {
  App,
  Button,
  Form,
  Input,
  InputRef,
  Modal,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Spin,
  TableColumnType,
  Tooltip,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { ButtonWrap } from './styles';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CloudUpload } from 'lucide-react';
import { dummyRequest } from '@/utils/dummyRequest';
import { UploadChangeParam } from 'antd/es/upload';
import { LoadingOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  analystService,
  deleteDocumentService,
  downloadCV,
  getCheckMask,
  getDocumentListService,
  uploadFileService,
} from '@/services/DocumentService';
import {
  CategoryType,
  IDocument,
  DocumentType,
  IDocumentUpload,
  UploadFileParams,
} from '@/types/document.type';
import dayjs from 'dayjs';
import useAuthStore from '@/store/authStore';
import PopoverConfirm from '@/components/PopoverConfirm/PopoverConfirm';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import { FilterDropdownProps } from 'antd/es/table/interface';
import useProject from '@/hooks/useProject';
import saveAs from 'file-saver';
import useNetworkStatus from '@/hooks/useNetworkStatus';
import useCalculateDynamicHeight from '@/hooks/useCalculateDynamicHeight';

const descStatics = {
  title: 'Static Documents Include: ',
  list: ['Project kickoff documents', 'SOW', 'Contract'],
};

const descDynamic = {
  title: 'Dynamic Documents includes: ',
  list: [
    'Meeting Reports (Daily, Weekly)',
    // 'Sprint Report',
    // 'Test Report, Test Cases',
    // 'Q&A',
    // 'TMS',
    'Jira (Currently only supporting issue and bug documentation generated from Jira, which needs to include the columns Issue Type, Summary, and Description.)',
  ],
};

const dataSources = [
  { value: 'jira', label: 'Jira' },
  { value: 'report_file', label: 'Report file' },
];

type DataIndex = keyof IDocument;

// const layout = {
//   labelCol: { span: 6 },
//   wrapperCol: { span: 16 },
// };

const defaultValues = {
  document_category: undefined,
  document_source: undefined,
  file: null,
};

export const DocumentsPageView = () => {
  const queryClient = useQueryClient();
  const { Option } = Select;
  const [form] = Form.useForm<IDocumentUpload>();
  const { Dragger } = Upload;
  const { message } = App.useApp();
  const { user } = useAuthStore();
  const dataProjectDetail = useProject();
  const project_id = dataProjectDetail?.project_id;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAnalystLoading, setIsAnalystLoading] = useState(false);
  const [categoryType, setCategoryType] = useState<CategoryType | null>(null);
  const [fileUploadState, setFileUploadState] = useState<UploadFile | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [searchText, setSearchText] = useState('');
  // const [searchedColumn, setSearchedColumn] = useState('');
  const isOnline = useNetworkStatus();
  const tableHeight = useCalculateDynamicHeight(350);

  const searchInput = useRef<InputRef>(null);
  const [backupFileError, setBackupFileError] = useState<UploadFileParams>();
  const [{ confirm }, contextHolder] = Modal.useModal();
  const { data: dataListDocument, isFetching: loadingListDocument } = useQuery({
    queryKey: ['getListDocument', project_id],
    queryFn: () => getDocumentListService(project_id as string),
    enabled: !!project_id, // Only enable the query if selectedProject is defined
  });

  const { data: dataCheckMask } = useQuery({
    queryKey: ['checkMask', project_id],
    queryFn: () => getCheckMask(project_id as string),
    refetchInterval: 5000, // Set the refetch interval to 5 seconds
    enabled: !!project_id,
  });

  const uploadFile = useMutation({
    mutationFn: ({
      formData,
      project_id,
      user_id,
      document_category,
      document_source,
      force_update,
    }: UploadFileParams) =>
      uploadFileService({
        formData,
        project_id,
        user_id,
        document_category,
        document_source,
        force_update,
      }),
    onSuccess: () => {
      setIsLoading(false);
      message.success('The file has been uploaded successfully.');
      setIsModalOpen(false);
      setCategoryType(null);
      setFileUploadState(null);
      form.resetFields();
      setBackupFileError(undefined);
      queryClient.invalidateQueries({ queryKey: ['getListDocument'] });
    },
    onError: (err: any, variables) => {
      console.log('err upload', err);
      setIsLoading(false);
      if (err.code === 'ERR_NETWORK') {
        message.error(
          'There was a network error. Please contact IT for assistance.',
        );
        return;
      }

      if (err.response.status === 409) {
        const changeVariables = {
          formData: variables.formData,
          project_id: variables.project_id,
          user_id: variables.user_id,
          document_category: variables.document_category,
          document_source: variables.document_source,
          force_update: true,
        };
        setBackupFileError(changeVariables);
        return;
      }

      message.error(
        err.response.data.detail
          ? err.response.data.detail
          : 'Something went wrong!',
      );
      setBackupFileError(undefined);
    },
  });

  const showConfirm = useCallback(() => {
    confirm({
      title: 'File exists',
      // icon: m,
      content: 'Are you sure you would like to replace the document?',
      onOk() {
        setIsLoading(true);
        backupFileError && uploadFile.mutate(backupFileError);
      },
      onCancel() {
        setBackupFileError(undefined);
      },
    });
    // eslint-disable-next-line
  }, [backupFileError]);

  const downloadFile = useMutation({
    mutationFn: ({
      project_id,
      document_id,
    }: {
      project_id: string;
      document_id: string;
      file_name: string;
    }) => downloadCV(project_id, document_id),
    onSuccess: (res, { file_name }) => {
      // Get content disposition
      const contentDisposition = res?.headers?.['content-disposition'] || '';

      // Create blob from response data
      const blob = new Blob([new Uint8Array(res)], {
        type: res.headers?.['content-type'] || 'application/octet-stream',
      });

      // Extract filename from content disposition or use provided file_name
      const serverFileName = contentDisposition
        .split('filename=')[1]
        ?.replace(/"/g, '');
      const fileName = serverFileName || file_name || 'download';

      // Use saveAs to trigger download
      saveAs(blob, fileName);

      message.success('The file has been downloaded successfully.');
    },
    onError: (error: any) => {
      console.log(error);
      message.error(error.response?.data?.detail || 'Download failed');
    },
  });

  const handlerFileChange = async (info: UploadChangeParam<UploadFile>) => {
    const { file } = info;
    if (file.status === 'removed' || file.status === 'error') {
      setFileUploadState(null);
    } else {
      setFileUploadState(file);
    }
  };

  const onRemoveFile = (file: UploadFile) => {
    console.log(file);
    setFileUploadState(null);
  };

  const props: UploadProps = {
    name: 'file',
    accept:
      '.doc,.docx,.pdf,.ppt,.pptx,.xls,.xlsx,.csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv',
    multiple: false,
    customRequest: dummyRequest,
    onChange: handlerFileChange,
    onRemove: onRemoveFile,
    maxCount: 1,
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    beforeUpload: (file: File) => {
      const isLt5M = file.size / 1024 / 1024 <= 20;
      const isValidType = [
        'application/msword', // .doc
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
        'application/pdf', // .pdf
        'application/vnd.ms-powerpoint', // .ppt
        'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
        'application/vnd.ms-excel', // .xls
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
        'text/csv', // .csv
      ].includes(file.type);

      if (!isLt5M) {
        message.error(`The maximum file size is 20MB.`);
      }
      if (!isValidType) {
        message.error(`The file is in an incorrect format.`);
      }

      return isLt5M && isValidType ? true : Upload.LIST_IGNORE;
    },
    fileList: fileUploadState ? [fileUploadState] : [],
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setCategoryType(null);
    setFileUploadState(null);
    setIsLoading(false);
    form.resetFields();
  };

  const analyst = useMutation({
    mutationFn: ({ project_id }: { project_id: string }) =>
      analystService(project_id),
    onSuccess: () => {
      setIsAnalystLoading(false);
      queryClient.invalidateQueries({ queryKey: ['getListDocument'] });
      message.success('The analysis of the file was successful.');
    },
    onError: (err: any) => {
      message.error(
        err.response.data.detail
          ? err.response.data.detail
          : 'Something went wrong!',
      );
      setIsAnalystLoading(false);
    },
  });

  const onAnalyst = () => {
    if (project_id) {
      setIsAnalystLoading(true);
      analyst.mutate({ project_id: project_id });
    }
  };

  const onDeleteDocument = useMutation({
    mutationFn: ({
      project_id,
      document_id,
    }: {
      project_id: string;
      document_id: string;
    }) => deleteDocumentService(project_id, document_id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getListDocument'],
      });
      message.success('The document has been deleted successfully.');
    },
    onError: (err) => {
      message.success(err.message);
    },
  });

  const handleDelete = (id: string) => {
    if (project_id && id) {
      onDeleteDocument.mutate({ project_id: project_id, document_id: id });
    }
  };

  const handleSearch = (
    // selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    // dataIndex: DataIndex,
  ) => {
    confirm();
    // setSearchText(selectedKeys[0]);
    // setSearchedColumn(dataIndex);
  };

  const handleReset = (
    clearFilters: () => void,
    confirm: FilterDropdownProps['confirm'],
    close: () => void,
  ) => {
    clearFilters();
    // setSearchText('');
    confirm();
    close();
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
  ): TableColumnType<IDocument> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(
              // selectedKeys as string[],
              confirm,
              // dataIndex
            )
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(
                // selectedKeys as string[],
                confirm,
                // dataIndex
              )
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters, confirm, close)
            }
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    // render: (text) =>
    //   searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //       searchWords={[searchText]}
    //       autoEscape
    //       textToHighlight={text ? text.toString() : ''}
    //     />
    //   ) : (
    //     text
    //   ),
  });

  const columns: ColumnsType<IDocument> = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      render: (_, record) => {
        const originalIndex = dataListDocument?.data.findIndex(
          (item) => item.document_id === record.document_id,
        );
        return originalIndex !== undefined ? originalIndex + 1 : 0;
      },
      width: 70,
    },

    {
      title: 'File Name',
      dataIndex: 'file_name',
      key: 'file_name',
      width: 500,
      ...getColumnSearchProps('file_name'),
    },
    {
      title: 'Category',
      dataIndex: 'document_category',
      key: 'document_category',
      width: 150,
      render: (value) => (
        <span style={{ textTransform: 'capitalize' }}>{value}</span>
      ),
      sorter: (a, b) => a.document_category.localeCompare(b.document_category),
    },
    {
      title: 'Uploaded Date',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 200,
      render: (value) => <span>{dayjs(value).format('YYYY/MM/DD')}</span>,
      sorter: (a, b) => dayjs(a.created_at).unix() - dayjs(b.created_at).unix(),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (value) => (
        <span>
          {value === DocumentType.VERIFY
            ? 'Verified'
            : value === DocumentType.UN_VERIFY
              ? 'Un-Verified'
              : 'N/a'}
        </span>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: 'Processing Status',
      dataIndex: 'processing_status',
      key: 'processing_status',
      width: 250,
      sorter: (a, b) => a.processing_status.localeCompare(b.processing_status),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          {record.status === DocumentType.UN_VERIFY && (
            <PopoverConfirm
              dataId={record.document_id}
              callBack={(id) => handleDelete(id)}
              isDisable={
                isAnalystLoading || record.processing_status === 'Masking'
              }
            />
          )}
          <Button
            type="text"
            icon={<DownloadOutlined />}
            onClick={() => {
              project_id &&
                downloadFile.mutate({
                  project_id: project_id,
                  document_id: record.document_id,
                  file_name: record.file_name,
                });
            }}
          />
        </Space>
      ),
      // ),
    },
  ];

  const onChangeRadio = (e: RadioChangeEvent) => {
    setCategoryType(e.target.value);
    form.setFieldsValue({ document_source: undefined, file: undefined });
    setFileUploadState(null);
  };

  const onFinish = async (dataForm: IDocumentUpload) => {
    const formUpload = new FormData();
    const file = dataForm.file.file.originFileObj;
    if (!isOnline) {
      message.error('You are offline. Please check your network connection.');
      return;
    }
    if (file && user?.user_id && project_id && categoryType) {
      formUpload.append('file', file);
      setIsLoading(true);
      uploadFile.mutate({
        formData: formUpload,
        project_id: project_id,
        user_id: user.user_id,
        document_category: dataForm.document_category,
        ...(dataForm.document_source && {
          document_source: dataForm.document_source,
        }),
        force_update: false,
      });
    }
  };

  useEffect(() => {
    if (backupFileError) {
      showConfirm();
    }
    // eslint-disable-next-line
  }, [backupFileError]);

  return (
    <>
      {contextHolder}
      <TitleGroup
        title={`${dataProjectDetail?.project_code ? dataProjectDetail?.project_code : ''} -  Documents List`}
        // subTitle="Project A’s documents are listed as below. User can update documents to generate risks. "
      />
      <Table
        columns={columns}
        dataSource={dataListDocument ? dataListDocument?.data : []}
        // style={{ width: '1000px', margin: '0 auto' }}
        scroll={{ x: 1000, y: tableHeight }}
        rowKey={'document_id'}
        loading={loadingListDocument}
      />
      <ButtonWrap>
        <Space>
          <Tooltip title={dataCheckMask?.message}>
            <Button
              type="primary"
              onClick={onAnalyst}
              disabled={
                isAnalystLoading ||
                (dataListDocument &&
                  dataListDocument.data?.every(
                    (item) => item.status === DocumentType.VERIFY,
                  )) ||
                !dataCheckMask?.success
              }
            >
              {!dataCheckMask?.success.toString()}
              Analyze Unverified Documents
              {isAnalystLoading && <LoadingOutlined />}
            </Button>
          </Tooltip>
          <Button type="primary" onClick={showModal}>
            Upload File
          </Button>
        </Space>
      </ButtonWrap>
      <Modal
        title="Document Upload"
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" type="primary" ghost onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            disabled={isLoading}
          >
            Upload
          </Button>,
        ]}
        // forceRender={true}
      >
        {isLoading && <Spin spinning={true} fullscreen />}
        {isModalOpen && (
          <>
            <h4>
              You need to upload at least one static file first before you can
              upload a dynamic file. And confirm at least one dependency to be
              able to analyze and return the content of the dynamic file.
            </h4>
            <Form
              // {...layout}
              form={form}
              onFinish={onFinish}
              initialValues={defaultValues}
            >
              <Form.Item
                name="document_category"
                label="Category:"
                rules={[{ required: true, message: 'Please select Category!' }]}
              >
                <Radio.Group onChange={onChangeRadio} value={categoryType}>
                  <Radio value={CategoryType.STATIC}>Static</Radio>
                  <Radio value={CategoryType.DYNAMIC}>Dynamic</Radio>
                </Radio.Group>
              </Form.Item>
              {categoryType === CategoryType.DYNAMIC ? (
                <>
                  <div style={{ color: '#000' }}>{descDynamic.title}</div>
                  <ul style={{ marginTop: 0, paddingLeft: '20px' }}>
                    {descDynamic.list.map((item, i) => (
                      <li key={i}>
                        <span style={{ fontSize: '13px' }}>{item}</span>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <>
                  <div style={{ color: '#000' }}>{descStatics.title}</div>
                  <ul style={{ marginTop: 0, paddingLeft: '20px' }}>
                    {descStatics.list.map((item, i) => (
                      <li key={i}>
                        <span style={{ fontSize: '13px' }}>{item}</span>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {categoryType === CategoryType.DYNAMIC && (
                <Form.Item
                  name="document_source"
                  label="File type"
                  rules={[
                    { required: true, message: 'Please select File type!' },
                  ]}
                >
                  <Select placeholder="Select File type">
                    {dataSources.map((source) => (
                      <Option key={source.value} value={source.value}>
                        {source.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item
                name="file"
                // label="Data Source"
                rules={[{ required: true, message: 'Please import file!' }]}
              >
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <CloudUpload />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  {/* <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from
              uploading company data or other band files
            </p> */}
                </Dragger>
              </Form.Item>

              <p>
                Formats accepted are: doc, docx, xls, xlsx, pdf, ppt, pptx, csv
              </p>
              {/* <hr /> */}
              {/* <p>If you do not have a file you can use the sample below:</p>
            <Button icon={<FileTextOutlined />}>
              Download Sample Template
            </Button> */}
            </Form>
          </>
        )}
      </Modal>
    </>
  );
};
