import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { CustomHeader, PageWrap } from '../MainLayout/styles';
import { Avatar, Button, Dropdown, MenuProps } from 'antd';
import { DownOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import useAuthStore from '@/store/authStore';
// import { useMediaQuery } from '@uidotdev/usehooks';

export const NoSidebarLayout = ({ children }: { children: ReactNode }) => {
  // const isMobile = useMediaQuery('only screen and (max-width : 992px)');
  const { logout, user } = useAuthStore();

  const handleLogOut = () => {
    logout();
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Button type="text" icon={<LogoutOutlined />} onClick={handleLogOut}>
          Logout
        </Button>
      ),
    },
  ];

  return (
    <PageWrap>
      <CustomHeader>
        <div className="headerLeft">
          <div className="logo">
            <Link to="/" style={{ cursor: 'pointer' }}>
              <span className="titleLogo">Forecast Vista</span>
            </Link>
          </div>
        </div>

        <div className="headerRight">
          <>
            <Dropdown menu={{ items }} placement="bottomRight" arrow>
              <div className="userInfo">
                <Avatar size={32} icon={<UserOutlined />} />
                <div className="userName">
                  {user ? user.user_id : 'Account'}
                </div>
                <DownOutlined style={{ marginLeft: '5px', color: '#ffffff' }} />
              </div>
            </Dropdown>
          </>
        </div>
        {/* {isMobile && (
          <Drawer title="Mobile Menu" onClose={onClose} open={openDrawer}>
            <Navbar $isMobile>
              <li>
                <NavLink to={'/page1'} className="nav-link">
                  Page 1
                </NavLink>
              </li>
              <li>
                <NavLink to={'/page2'} className="nav-link">
                  Page 2
                </NavLink>
              </li>
            </Navbar>
          </Drawer>
        )} */}
      </CustomHeader>
      {children}
    </PageWrap>
  );
};
