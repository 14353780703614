import {
  ICheckMaskRes,
  IDocumentRes,
  UploadFileParams,
} from '@/types/document.type';
import { axiosInstance } from '@/utils/axios';

export const getDocumentListService = async (
  project_id: string,
): Promise<IDocumentRes> => {
  const response = await axiosInstance.get(
    `/project/${project_id}/document/list_document`,
  );
  return response.data;
};

export const getCheckMask = async (
  project_id: string,
): Promise<ICheckMaskRes> => {
  const response = await axiosInstance.post(
    `/project/${project_id}/check_mask`,
  );
  return response.data;
};

export const analystService = async (
  project_id: string,
): Promise<IDocumentRes> => {
  const response = await axiosInstance.post(`/project/${project_id}/analyse`);
  return response.data;
};

export const uploadFileService = async ({
  formData,
  project_id,
  user_id,
  document_category,
  document_source,
  force_update = false,
}: UploadFileParams): Promise<any> => {
  const response = await axiosInstance.post(
    `/project/${project_id}/upload?user_id=${user_id}&document_category=${document_category}${document_source ? '&document_source=' + document_source : ''}&force_update=${force_update}`,
    formData,
    {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    },
  );
  return response.data;
};

export const deleteDocumentService = async (
  project_id: string,
  document_id: string,
) => {
  const response = await axiosInstance.delete(
    `/project/${project_id}/document/${document_id}`,
  );
  return response.data;
};
export const downloadCV = async (
  project_id: string,
  document_id: string,
): Promise<any> => {
  const response = await axiosInstance.get(
    `/project/${project_id}/download?document_id=${document_id}`,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'arraybuffer', // This is crucial for binary data
    },
  );
  return response.data;
};
