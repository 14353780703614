import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import GlobalStyles from './styles/globalStyles';

import reportWebVitals from './reportWebVitals';
import { App, ConfigProvider } from 'antd';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import themeConfigs from './config/themeConfigs';
import AppWrap from './App';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './config/msalConfig';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const theme = {
  token: {
    borderRadius: 6,
    colorPrimary: themeConfigs.primaryColor,
    colorInfo: themeConfigs.primaryColor,
    colorTextBase: themeConfigs.textColor,
    fontFamily: 'Inter, "Helvetica Neue", sans-serif',
  },
  components: {
    Button: {
      primaryShadow: 'none',
      defaultShadow: 'none',
      dangerShadow: 'none',
      algorithm: true, // Enable algorithm
    },
    Tag: {
      borderRadiusSM: 4,
      lineHeight: 24,
    },
    Modal: {
      headerBg: themeConfigs.primaryColor,
      titleColor: '#ffffff',
      titleFontSize: 20,
    },
    Form: {
      labelColor: '#1D2B4F',
    },
    Table: {
      headerBg: 'rgb(234, 236, 240)',
      headerColor: 'rgb(102, 112, 133)',
      headerSplitColor: 'rgba(102, 112, 133, 0.1)',
    },
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ConfigProvider theme={theme}>
        <MsalProvider instance={msalInstance}>
          <App>
            <GlobalStyles />
            <AppWrap />
          </App>
        </MsalProvider>
      </ConfigProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
