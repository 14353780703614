import { TitleGroup } from '@/components/TitleGroup';
import {
  App,
  Button,
  Input,
  InputRef,
  Modal,
  Space,
  Table,
  TableColumnType,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useRef, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { AddAndEditModal } from '../../components/AddAndEditDependencyModal';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  deleteListDependencyService,
  getDependencyListStatusService,
} from '@/services/DependencyService';
import { DependencyStatus, IDependency } from '@/types/dependency.type';
import dayjs from 'dayjs';
// import { ButtonWrap } from './styles';
import { SearchOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import { FilterDropdownProps } from 'antd/es/table/interface';
import useProject from '@/hooks/useProject';
import { formatDate, getUnixTime } from '@/utils/checkDate';
import useCalculateDynamicHeight from '@/hooks/useCalculateDynamicHeight';

type DataIndex = keyof IDependency;
export const ConfirmedDependencyPageView = () => {
  const [{ confirm }, contextHolder] = Modal.useModal();
  const queryClient = useQueryClient();
  const dataProjectDetail = useProject();
  const project_id = dataProjectDetail?.project_id;
  const { message } = App.useApp();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dataSelected, setDataSelected] = useState<IDependency | undefined>();
  const [selectedRowKeysDelete, setSelectedRowKeysDelete] = useState<
    React.Key[]
  >([]);
  // const [searchText, setSearchText] = useState('');
  // const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const tableHeight = useCalculateDynamicHeight(310);

  const {
    data: dataListDependencyConfirmed,
    isFetching: loadingListDependencyConfirmed,
  } = useQuery({
    queryKey: ['getListDependencyConfirmed', project_id],
    queryFn: () =>
      getDependencyListStatusService(
        DependencyStatus.CONFIRMED,
        project_id as string,
      ),
    enabled: !!project_id, // Only enable the query if selectedProject is defined
  });

  const onEditDependency = (data: IDependency) => {
    setDataSelected(data);
    setIsOpen(true);
  };

  const onDelete = useMutation({
    mutationFn: ({
      project_id,
      data,
    }: {
      project_id: string;
      data: React.Key[];
    }) => deleteListDependencyService(project_id, data),
    onSuccess: () => {
      message.success('The dependency has been deleted successfully.');
      queryClient.invalidateQueries({
        queryKey: ['getListDependencyConfirmed'],
      });
      setSelectedRowKeysDelete([]);
    },
    onError: (err) => {
      message.error(err.message);
    },
  });

  // const onDeleteQuestion = useMutation({
  //   mutationFn: (id: string) => deleteDependencyService(id),
  //   onSuccess: () => {
  //     queryClient.invalidateQueries({
  //       queryKey: ['getListDependencyConfirmed'],
  //     });
  //     message.success('Delete dependency successfully');
  //   },
  //   onError: (err) => {
  //     message.success(err.message);
  //   },
  // });

  // const handleDelete = (id: string) => {
  //   onDeleteQuestion.mutate(id);
  // };

  const showConfirm = () => {
    confirm({
      title: 'Confirm the action',
      // icon: m,
      content:
        'Are you sure you would like to remove this dependency from the Confirmed Dependency List?',
      onOk() {
        project_id &&
          onDelete.mutate({
            project_id: project_id,
            data: selectedRowKeysDelete,
          });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleSearch = (
    // selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    // dataIndex: DataIndex,
  ) => {
    confirm();
    // setSearchText(selectedKeys[0]);
    // setSearchedColumn(dataIndex);
  };

  const handleReset = (
    clearFilters: () => void,
    confirm: FilterDropdownProps['confirm'],
    close: () => void,
  ) => {
    clearFilters();
    // setSearchText('');
    confirm();
    close();
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
  ): TableColumnType<IDependency> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(
              // selectedKeys as string[],
              confirm,
              // dataIndex
            )
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(
                // selectedKeys as string[],
                confirm,
                // dataIndex
              )
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters, confirm, close)
            }
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      const cellValue = record[dataIndex];
      return cellValue
        ? cellValue
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : false;
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    // render: (text) =>
    //   searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //       searchWords={[searchText]}
    //       autoEscape
    //       textToHighlight={text ? text.toString() : ''}
    //     />
    //   ) : (
    //     text
    //   ),
  });

  const columns: ColumnsType<IDependency> = [
    {
      title: 'ID',
      // dataIndex: 'no',
      dataIndex: 'specific_id',
      // render: (_, record) => {
      //   const originalIndex = dataListDependencyConfirmed?.data.findIndex(
      //     (item) => item._id === record._id,
      //   );
      //   return originalIndex !== undefined ? originalIndex + 1 : 0;
      // },
      sorter: (a, b) => a.specific_id - b.specific_id,
      width: 70,
    },
    {
      title: 'Dependency Name',
      dataIndex: 'dependency_name',
      width: 366,
      ...getColumnSearchProps('dependency_name'),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: 100,
      render: (value) => (
        <span style={{ textTransform: 'capitalize' }}>{value}</span>
      ),
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: 'Dependency Details',
      dataIndex: 'dependency_detail',
      width: 400,
      ...getColumnSearchProps('dependency_detail'),
    },
    {
      title: 'Deadline',
      dataIndex: 'deadline',
      width: 150,
      render: (value, record) => (
        <span>{record.is_no_deadline ? 'No Deadline' : formatDate(value)}</span>
      ),
      sorter: (a, b) => getUnixTime(a.deadline) - getUnixTime(b.deadline),
    },

    {
      title: 'Data Source',
      dataIndex: 'data_source',
      width: 180,
      // ellipsis: true,
      render: (value) => (
        <div
          style={{
            textTransform: 'capitalize',
            width: '150px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          title={value}
        >
          {value}
        </div>
      ),
      ...getColumnSearchProps('data_source'),
    },

    {
      title: 'Generated Date',
      dataIndex: 'created_at',
      width: 250,
      render: (value) => <span>{dayjs(value).format('YYYY/MM/DD')}</span>,
      sorter: (a, b) => getUnixTime(a.created_at) - getUnixTime(b.created_at),
    },
    {
      title: 'Last Updated Date ',
      dataIndex: 'updated_at',
      width: 200,
      render: (value) => <span>{dayjs(value).format('YYYY/MM/DD')}</span>,
      sorter: (a, b) => getUnixTime(a.updated_at) - getUnixTime(b.updated_at),
    },

    {
      title: 'Action',
      key: 'action',
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <EditOutlined onClick={() => onEditDependency(record)} />
          {/* <PopoverConfirm
            dataId={record.dependency_id}
            callBack={(id) => handleDelete(id)}
          /> */}
        </Space>
      ),
    },
  ];

  const rowSelectionDelete = {
    selectedRowKeys: selectedRowKeysDelete,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeysDelete(selectedRowKeys);
    },
    // getCheckboxProps: (record: IDependency) => ({
    //   disabled: record.status === 'confirmed', // Disable checkbox if status is confirmed
    // }),
  };

  return (
    <>
      <TitleGroup
        title={`${dataProjectDetail?.project_code ? dataProjectDetail?.project_code : ''} -  Confirmed Dependency List`}
        // subTitle="Approved dependencies are listed below. User can add new dependencies or update/delete existing dependencies if needed."
        button={
          <Button
            type="primary"
            onClick={() => {
              showConfirm();
            }}
            disabled={
              selectedRowKeysDelete && selectedRowKeysDelete?.length === 0
            }
          >
            Delete
          </Button>
        }
      />
      <Table
        columns={columns}
        rowSelection={rowSelectionDelete}
        dataSource={
          dataListDependencyConfirmed ? dataListDependencyConfirmed?.data : []
        }
        rowKey={'dependency_id'}
        scroll={{ x: 1300, y: tableHeight }}
        loading={loadingListDependencyConfirmed}
      />
      <AddAndEditModal
        isModalOpen={isOpen}
        setIsModalOpen={(data) => {
          setIsOpen(data);
          !data && setDataSelected(undefined);
        }}
        dataEdit={dataSelected}
      />
      {/* <ButtonWrap style={{ marginBottom: '24px', marginTop: '16px' }}>
        <Button
          type="primary"
          onClick={() => {
            showConfirm();
          }}
          disabled={
            selectedRowKeysDelete && selectedRowKeysDelete?.length === 0
          }
        >
          Delete
        </Button>
      </ButtonWrap> */}
      {contextHolder}
    </>
  );
};
