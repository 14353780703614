import styled from 'styled-components';
import { Button } from 'antd';

export const WrapperButton = styled.div`
  text-align: end;
  margin: 10px 0px;
`;

export const SpanDel = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: #000000;
`;
export const CustomButtonNo = styled(Button)`
  border: 1px solid #ff4d4f;
  border-radius: 4px;
  /* padding: 15px 1px; */
  padding: 4px 12px;
  background-color: white;
  color: #a8071a;
`;
export const CustomButtonYes = styled(Button)`
  border: 1px solid #ff4d4f;
  border-radius: 4px;
  padding: 4px 12px;
  background-color: #ff4d4f;
  color: white;
  margin-left: 10px;
`;
