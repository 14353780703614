import themeConfigs from '@/config/themeConfigs';
import styled from 'styled-components';

export const TitleGroupStyle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  h3 {
    font-weight: 500;
    font-size: 16px;
    margin: 0;
    color: ${themeConfigs.titleColor};
  }
  p {
    font-size: 13px;
    margin: 0;
  }
`;
