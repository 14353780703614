import { IProject, IProjectRes } from '@/types/project.type';
import { axiosInstance } from '@/utils/axios';

export const getProjectListService = async (
  user_id: string,
): Promise<IProjectRes> => {
  const response = await axiosInstance.get(`/project/search/${user_id}`);
  return response.data;
};

export const getProjectDetailService = async (
  id: string,
): Promise<IProject> => {
  const response = await axiosInstance.get(`project/id/${id}`);
  return response.data?.data;
};
