import {
  IEvaluateRes,
  IEvaluateUpdate,
  IEvaluateUpdateRes,
} from '@/types/evaluate.type';
import { axiosInstance } from '@/utils/axios';

export const getEvaluateListService = async (
  project_id: string,
): Promise<IEvaluateRes> => {
  const response = await axiosInstance.get(
    `/project/${project_id}/dependency/evaluate`,
  );
  return response.data;
};

export const submitEvaluateService = async (
  project_id: string,
  data: IEvaluateUpdate[],
): Promise<IEvaluateUpdateRes> => {
  const response = await axiosInstance.post(
    `/project/${project_id}/dependency/evaluate_submit`,
    data,
  );
  return response.data.data;
};

export const saveEvaluateService = async (
  project_id: string,
  data: IEvaluateUpdate[],
): Promise<IEvaluateUpdateRes> => {
  const response = await axiosInstance.post(
    `/project/${project_id}/dependency/evaluate_save`,
    data,
  );
  return response.data.data;
};
