import { IDataLogin } from '@/types/auth.type';
import { IUser } from '@/types/user.type';
import { axiosInstance, axiosNoAuthInstance } from '@/utils/axios';

export const loginService = async (data: IDataLogin): Promise<IUser> => {
  const response = await axiosNoAuthInstance.post('/auth/login', data);
  return response?.data?.data;
};

export const logoutService = async (): Promise<IUser> => {
  const response = await axiosInstance.get('/logout');
  return response.data;
};
