import {
  DependencyStatus,
  IDependency,
  IDependencyConflictRes,
  IDependencyConflictSelect,
  IDependencyCreate,
  IDependencyRes,
} from '@/types/dependency.type';
import { axiosInstance } from '@/utils/axios';
import React from 'react';

export const getDependencyListService = async (
  project_id: string,
): Promise<IDependencyRes> => {
  const response = await axiosInstance.get(
    `/project/${project_id}/dependency/list_dependency`,
  );
  return response.data;
};

export const getDependencyListStatusService = async (
  status: DependencyStatus,
  project_id: string,
): Promise<IDependencyRes> => {
  const response = await axiosInstance.get(
    `project/${project_id}/dependency/list_dependency_status?status=${status}`,
  );
  return response.data;
};

export const getConflictDependencyListService = async (
  project_id: string,
): Promise<IDependencyConflictRes> => {
  const response = await axiosInstance.get(
    `/project/${project_id}/dependency/list_conflict_dependency`,
  );
  return response.data;
};

export const createDependencyService = async (
  project_id: string,
  data: IDependencyCreate,
): Promise<IDependency> => {
  const response = await axiosInstance.post(
    `/project/${project_id}/dependency`,
    data,
  );
  return response.data;
};

export const updateDependencyService = async (
  project_id: string,
  id: string,
  data: IDependencyCreate,
): Promise<IDependency> => {
  const response = await axiosInstance.put(
    `/project/${project_id}/dependency/${id}
  `,
    data,
  );
  return response.data;
};

export const deleteDependencyService = async (id: string) => {
  const response = await axiosInstance.delete(`/dependency/${id}`);
  return response.data;
};

export const deleteListDependencyService = async (
  project_id: string,
  listID: React.Key[],
) => {
  const response = await axiosInstance.post(
    `/project/${project_id}/dependency/delete_confirm_list`,
    listID,
  );
  return response.data;
};

export const confirmConflictService = async (
  project_id: string,
  data: IDependencyConflictSelect[],
): Promise<any> => {
  const response = await axiosInstance.post(
    `/project/${project_id}/dependency/confirm_conflict_dependency`,
    data,
  );
  return response.data;
};

export const confirmService = async (
  project_id: string,
  data: React.Key[],
): Promise<any> => {
  const response = await axiosInstance.post(
    `/project/${project_id}/dependency/confirm_list_dependency`,
    data,
  );
  return response.data;
};
