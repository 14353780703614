import { Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

export const NotFound = () => {
  const { Title } = Typography;
  return (
    <div className="not-found">
      <Title>Not Found</Title>
      <Link to="/" className="link-home">
        Go Home
      </Link>
    </div>
  );
};
