import { getProjectDetailService } from '@/services/ProjectService';
import { IProjectDetailInfo } from '@/types/project.type';
import { useQuery } from '@tanstack/react-query';
import { App } from 'antd';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const useProject = () => {
  const { project_id } = useParams<{ project_id: string }>();
  const { message } = App.useApp();
  const navigate = useNavigate();

  const {
    data: dataProjectDetail,
    error: errorProjectDetail,
    isFetching,
    isError,
  } = useQuery<IProjectDetailInfo, any>({
    queryKey: ['getProjectList', project_id], // Include project_id in the queryKey to refetch on change
    queryFn: () => getProjectDetailService(project_id as string),
    enabled: !!project_id,
    retry: false,
  });

  useEffect(() => {
    if (isError && !isFetching) {
      const errorMessage =
        errorProjectDetail.response?.data?.detail ||
        errorProjectDetail.message ||
        'Something went wrong';
      message.error(errorMessage);
      if (
        errorProjectDetail.response.status === 403 ||
        errorProjectDetail.response.status === 401
      ) {
        navigate(`/projects`);
      }
    }
  }, [isError, isFetching, errorProjectDetail, message, navigate]);

  return dataProjectDetail;
};

export default useProject;
