import dayjs from 'dayjs';

export const formatDate = (value: string) => {
  const date = dayjs(value);
  if (date.isValid()) {
    return date.format('DD/MM/YYYY');
  } else {
    return value;
  }
};

export const getUnixTime = (date: string | dayjs.Dayjs | null) => {
  // console.log('date', date);
  if (!date || date === 'No Deadline') {
    return Infinity; // Set invalid dates to Infinity to move them to the end
  }
  const parsedDate = dayjs(date);
  return parsedDate.isValid() ? parsedDate.unix() : Infinity;
};
