import { getProjectDetailService } from '@/services/ProjectService';
import useAuthStore from '@/store/authStore';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
const useProject = () => {
  const { project_id } = useParams<{ project_id: string }>();
  const { setSelectedProject } = useAuthStore();
  const { data: dataProjectList } = useQuery({
    queryKey: ['getProjectList'],
    queryFn: () => getProjectDetailService(project_id as string),
    enabled: !!project_id,
  });

  useEffect(() => {
    if (dataProjectList) {
      setSelectedProject(dataProjectList);
    }

    return () => {
      setSelectedProject(null);
    };
    // eslint-disable-next-line
  }, [dataProjectList]);

  useEffect(() => {
    return () => {
      setSelectedProject(null);
    };
    // eslint-disable-next-line
  }, []);
};

export default useProject;
