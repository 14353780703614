import useAuthStore from '@/store/authStore';
// import { ITokens } from '@/type/auth.type';
// import { IUser } from '@/type/user.type';
import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;
const contentType = 'application/json';

// let refreshTokenRequest: Promise<ITokens> | null = null;

export const axiosNoAuthInstance = axios.create({
  baseURL,
  headers: { 'Content-type': contentType },
});

export const axiosInstance = axios.create({
  baseURL,
  headers: { 'Content-type': contentType },
});

const getToken = () => useAuthStore.getState()?.user?.access_token;

// const setTokens = (tokens: ITokens | null) => useAuthStore.getState().login;

// const logoutUser = () => {
//   const userStore = useAuthStore.getState();
//   userStore.logout();
// };

// const isExpired = (date: string) => {
//   return new deadline(date).getTime() < deadline.now();
// };

// const getRefreshToken = async (refreshToken: string) => {
//   const tokensData = await axiosNoAuthInstance.post<IUser>(
//     '/auth/refresh-tokens',
//     { refreshToken },
//   );
//   if (![200, 201].includes(tokensData?.status)) throw tokensData;
//   return tokensData.data.id;
// };

// const refreshToken = async (tokens?: ITokens | null) => {
//   try {
//     if (!tokens) {
//       tokens = getToken();
//       if (!tokens) throw new Error('token not found');
//     }

//     if (isExpired(tokens.refresh.expires))
//       throw new Error('refresh token expired');
//     refreshTokenRequest =
//       refreshTokenRequest ?? getRefreshToken(tokens.refresh.token);
//     const newToken = await refreshTokenRequest;
//     // reset token request for the next expiration
//     refreshTokenRequest = null;
//     setTokens(newToken);
//     return newToken;
//   } catch (err) {
//     console.error(err);
//     // reset tokens
//     logoutUser();
//     return null;
//   }
// };

async function getAccessToken() {
  try {
    const tokens = getToken();
    if (!tokens) return null;

    // if (isExpired(tokens.access.expires)) return refreshToken(tokens);
    // return tokens?.access.token;
    return tokens;
  } catch {
    return null;
  }
}

axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = await getAccessToken();
    if (accessToken) {
      config.headers.Authorization = accessToken && `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// Response interceptor for API calls
axiosInstance.interceptors.response.use(
  (response) => response,
  async function (error) {
    const originalRequest = error.config;
    if (
      [401, 403].includes(error?.response?.status) &&
      !originalRequest._retry
    ) {
      // originalRequest._retry = true;
      // const accessToken = await refreshToken();
      // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      // return axios(originalRequest);
      // logoutUser();
    }
    return Promise.reject(error);
  },
);
