import React from 'react';
import { ItemText, ItemWrap } from './styles';
export const ProjectContentItem = ({
  prefix,
  text,
}: {
  prefix?: React.ReactNode;
  text: string;
}) => {
  return (
    <ItemWrap>
      {prefix}
      <ItemText>{text}</ItemText>
    </ItemWrap>
  );
};
