import themeConfigs from '@/config/themeConfigs';
import { Col, ColProps, Row } from 'antd';
import styled from 'styled-components';

interface CustomColProps {
  isLastRow: boolean;
  isLastFourColumns: boolean;
}

export const GridContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto; // This centers the container
  padding: 0 15px; // Optional: adds some padding on the sides
`;

export const StyledRow = styled(Row)`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledCol = styled(Col)<ColProps & CustomColProps>`
  padding: 16px;
  border-bottom: ${(props) =>
    props.isLastRow || props.isLastFourColumns ? 'none' : '1px solid #667085'};
  flex: 0 0 25%;
  max-width: 25%;
`;
export const ProgressCircleItem = styled.div`
  text-align: center;
  .title {
    margin-bottom: 8px;
    display: inline-block;
  }
  .ProgressCircleWrap {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 16px 8px;
    box-shadow: -7px 5px 10px 0px rgba(0, 0, 0, 0.2);
  }
  .ant-progress-text {
    color: ${themeConfigs.primaryColor} !important;
  }
  .status {
    margin-top: 8px;
    display: inline-block;
  }
`;
