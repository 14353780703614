import { Dayjs } from 'dayjs';

export enum DependencyStatus {
  DRAFT = 'draft',
  GENERATED = 'generated',
  CONFIRMED = 'confirmed',
}

export interface IDependency {
  _id: string;
  dependency_name: string;
  type: string;
  dependency_detail: string;
  deadline: string | Dayjs | null;
  // Third_Party?: string;
  is_no_deadline: boolean;
  data_source?: string;
  other_text?: string;
  project_id?: string;
  dependency_id: string;
  conflict_id?: string;
  is_conflict: boolean;
  created_at: string;
  updated_at: string;
  is_confirmed?: boolean;
  status: DependencyStatus;
  specific_id: number;
}

export interface IDependencyConflictSelect
  extends Pick<IDependency, 'conflict_id' | 'dependency_id'> {}

export interface IDependencyCreate
  extends Pick<
    IDependency,
    | 'dependency_name'
    | 'type'
    | 'dependency_detail'
    | 'deadline'
    | 'is_no_deadline'
    // | 'Third_Party'
    | 'data_source'
    | 'other_text'
    | 'project_id'
    | 'status'
  > {}

export interface IDependencyRes {
  data: IDependency[];
}

export interface IDependencyConflict {
  conflict_id: string;
  items: IDependency[];
}

export interface IDependencyConflictRes {
  data: IDependencyConflict[];
}
