import styled from 'styled-components';
import themeConfigs from '@/config/themeConfigs';

export const EditableWrap = styled.div<{ $isDisabled?: boolean }>`
  cursor: ${(props) => (props.$isDisabled ? 'auto' : 'pointer')};
  background-color: ${(props) =>
    props.$isDisabled ? '#f8f8f8' : 'transparent'};
  width: 100%;
  min-height: 30px;
  line-height: 30px;
  padding: 0 12px;
  border: ${(props) =>
    props.$isDisabled ? 'transparent' : '1px solid #d6d6d6'};
  border-radius: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    border-color: ${(props) =>
      props.$isDisabled ? 'transparent' : themeConfigs.primaryColor};
  }
`;
