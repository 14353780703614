import styled from 'styled-components';
import imageLogin1 from '@/assets/images/imageLogin1.svg';

export const LoginWrap = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;
export const LoginLeft = styled.div`
  position: relative;
  height: 100vh;
  width: 50%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  button {
    font-weight: 700;
    height: 60px;
    min-width: 450px;
  }
  @media (max-width: 992px) {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    height: auto;
    width: auto;
    padding: 24px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    width: 100%;
    button {
      min-width: auto;
      width: 100%;
    }
  }
`;
export const LoginRight = styled.div`
  position: relative;
  height: 100vh;
  width: 50%;
  background-image: url(${imageLogin1});
  background-size: cover;
  background-position: center;
  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const LoginTitle = styled('h1')`
  font-size: 40px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  color: #000000;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

export const LoginDesc = styled('p')`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
  margin-top: 0;
  margin-bottom: 50px;
  color: #000000;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;
