import { ChartFeedBackComponent } from '@/components/ChartFeedBackComponent';
import themeConfigs from '@/config/themeConfigs';
import {
  getAllFeedbackMessageService,
  getAllFeedbackService,
  getMessageDailyService,
} from '@/services/FeedbackService';
import { dateFormatterForChart } from '@/utils/dateFormatterForChart';

import { transformDataChart } from '@/utils/transformDataChart';
import { useQuery } from '@tanstack/react-query';
import { Card, Empty, List, Select, Skeleton, Space, Tag } from 'antd';
import { useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip as TooltipChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

export const AllFeedbackPageView = () => {
  const { Option } = Select;
  const [duration, setDuration] = useState('7'); // Default duration

  const { data: dataAllFeedback, isFetching: isAllFeedbackFetching } = useQuery(
    {
      queryKey: ['getAllFeedbackService', duration],
      queryFn: () => getAllFeedbackService(duration),
    },
  );

  const { data: dataMessageDaily, isFetching: isMessageDailyFetching } =
    useQuery({
      queryKey: ['getMessageDailyService', duration],
      queryFn: () => getMessageDailyService(duration),
    });

  const { data: getAllFeedbackMessage, isFetching: isFeedbackMessageFetching } =
    useQuery({
      queryKey: ['getAllFeedbackMessage', duration],
      queryFn: () => getAllFeedbackMessageService(duration),
      select(data) {
        return Object.entries(data).map(([user, message]) => ({
          user,
          message,
        })) as {
          user: string;
          message: number;
        }[];
      },
    });

  const handleDurationChange = (value: string) => {
    setDuration(value); // Update the duration when a new option is selected
  };

  // Transform the data to be compatible with recharts

  return (
    <>
      {isAllFeedbackFetching ||
      isFeedbackMessageFetching ||
      isMessageDailyFetching ? (
        <Skeleton active={true} />
      ) : (
        <div>
          <Select
            value={duration}
            style={{ width: 120 }}
            onChange={handleDurationChange}
          >
            <Option value="3">3 Days</Option>
            <Option value="7">7 Days</Option>
            <Option value="15">15 Days</Option>
            <Option value="30">30 Days</Option>
          </Select>

          {dataAllFeedback && dataAllFeedback.length > 0 ? (
            <>
              <ChartFeedBackComponent
                title={'Reaction'}
                chartData={transformDataChart(dataAllFeedback)}
              />
            </>
          ) : (
            <>
              <Empty />
            </>
          )}
          <h2>
            <Space align="center">
              <span>Message</span>
              <Tag
                color={themeConfigs.primaryColor}
                style={{ verticalAlign: 'middle' }}
              >
                {/* {totalCounts.like + totalCounts.dislike + totalCounts.other} */}
                {dataMessageDaily && dataMessageDaily?.length
                  ? dataMessageDaily.reduce(
                      (acc: any, entry: { num_messages: any }) =>
                        acc + entry.num_messages,
                      0,
                    )
                  : 0}
              </Tag>
            </Space>
          </h2>
          {dataMessageDaily && dataMessageDaily.length > 0 ? (
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                // width={500}
                // height={300}
                data={dataMessageDaily}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" tickFormatter={dateFormatterForChart} />
                <YAxis allowDecimals={false} />
                <TooltipChart />
                <Legend />
                <Bar
                  dataKey="num_messages"
                  fill={themeConfigs.primaryColor}
                  // activeBar={<Rectangle fill="pink" stroke="blue" />}
                />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <>
              <Empty />
            </>
          )}

          <div>
            <h2>
              <Space align="center">
                <span>Message of user</span>
                <Tag
                  color={themeConfigs.primaryColor}
                  style={{ verticalAlign: 'middle' }}
                >
                  {/* {totalCounts.like + totalCounts.dislike + totalCounts.other} */}
                  {getAllFeedbackMessage && getAllFeedbackMessage?.length
                    ? getAllFeedbackMessage.reduce(
                        (acc, entry) => acc + entry.message,
                        0,
                      )
                    : 0}
                </Tag>
              </Space>
            </h2>

            <List
              grid={{
                gutter: 16,
                xs: 1,
                sm: 2,
                md: 4,
                lg: 4,
                xl: 6,
                xxl: 3,
              }}
              dataSource={getAllFeedbackMessage ? getAllFeedbackMessage : []}
              renderItem={(item) => (
                <List.Item>
                  <Card title={item.user}>
                    Number of messages: {<b>{item.message}</b>}
                  </Card>
                </List.Item>
              )}
              pagination={{
                // onChange: (page) => setCurrent(page),
                pageSize: 6,
                // total: getFeedbackMessage ? getFeedbackMessage.length : 0,
                // showSizeChanger: false, // Optional: hide size changer
              }}
              locale={{
                emptyText: <Empty />,
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
