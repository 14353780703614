import { IProject } from '@/types/project.type';
import { ProjectContentItem } from '../ProjectContentItem';
import { ItemTitle, ProjectItemWrap } from './styles';
export const ProjectsItem = ({
  itemProject,
  goToDocument,
}: {
  itemProject: IProject;
  goToDocument: (id: string) => void;
}) => {
  return (
    <>
      <ProjectItemWrap onClick={() => goToDocument(itemProject._id)}>
        <ItemTitle>{itemProject.project_code}</ItemTitle>
        <ProjectContentItem
          text="Members"
          prefix={
            <span
              style={{
                height: '38px',
                marginRight: '12px',
                display: 'inline-block',
                fontSize: '15px',
                fontWeight: 700,
                textAlign: 'center',
                lineHeight: '38px',
              }}
            >
              {itemProject?.member_list?.length}
            </span>
          }
        />
        <ProjectContentItem
          text="Risks"
          prefix={
            <span
              style={{
                height: '38px',
                marginRight: '12px',
                display: 'inline-block',
                fontSize: '15px',
                fontWeight: 700,
                textAlign: 'center',
                lineHeight: '38px',
                color: 'red',
              }}
            >
              {itemProject?.num_risk}
            </span>
          }
        />
        <ProjectContentItem
          text="Confirmed Dependencies"
          prefix={
            <span
              style={{
                height: '38px',
                marginRight: '12px',
                display: 'inline-block',
                fontSize: '15px',
                fontWeight: 700,
                textAlign: 'center',
                lineHeight: '38px',
              }}
            >
              {itemProject?.num_confirm_dependency}
            </span>
          }
        />

        <ProjectContentItem
          text="Evaluate Rate "
          prefix={
            <span
              style={{
                height: '38px',
                marginRight: '12px',
                display: 'inline-block',
                fontSize: '15px',
                fontWeight: 700,
                textAlign: 'center',
                lineHeight: '38px',
              }}
            >
              {itemProject?.label_rate?.raw
                ? itemProject?.label_rate?.raw
                : 'N/a'}
            </span>
          }
        />

        {/* {data.map((item: any, i: number) => {
        return (
          <ProjectContentItem key={i} text={item.content} prefix={item.count} />
        );
      })} */}
      </ProjectItemWrap>
      {/* <ItemTitle
        style={{ textAlign: 'center', color: '#000000', marginTop: '8px' }}
      >
        {itemProject.project_name}
      </ItemTitle> */}
    </>
  );
};
