// import { typeSelectedProject } from '@/types/project.type';
// import { IProjectDetailInfo } from '@/types/project.type';
import { IUser } from '@/types/user.type';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface IAuthState {
  user: IUser | null;
  // selectedProject: IProjectDetailInfo | null;
  // isAuthenticated: boolean;
  // setSelectedProject(project: IProjectDetailInfo | null): void;
  login(user: IUser | null): void;
  logout(): void;
}

const useAuthStore = create<IAuthState>()(
  devtools(
    persist(
      (set) => ({
        user: null,
        // selectedProject: null,
        // setSelectedProject: (project) => set({ selectedProject: project }),
        // isAuthenticated: false,
        login: (user) => set({ user }),
        logout: () => set({ user: null }),
      }),
      {
        name: 'auth-storage',
      },
    ),
  ),
);

export default useAuthStore;
