import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  App,
  Button,
  Flex,
  Form,
  Input,
  Modal,
  Select,
  Space,
  // Space,
  // Popconfirm,
  Table,
} from 'antd';
import type {
  InputRef,
  // TableColumnType
} from 'antd';
import type { FormInstance } from 'antd/es/form';
import { EditableWrap } from './styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getEvaluateListService,
  saveEvaluateService,
  submitEvaluateService,
} from '@/services/EvaluateService';
import { useParams } from 'react-router-dom';
import { IEvaluateItem, IEvaluateUpdate } from '@/types/evaluate.type';
// import themeConfigs from '@/config/themeConfigs';
// import dayjs from 'dayjs';
// import { FilterDropdownProps } from 'antd/es/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/es/table';
import {
  FilterConfirmProps,
  FilterDropdownProps,
} from 'antd/es/table/interface';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import useCalculateDynamicHeight from '@/hooks/useCalculateDynamicHeight';
import { formatDate, getUnixTime } from '@/utils/checkDate';
import useAuthStore from '@/store/authStore';
import useProject from '@/hooks/useProject';
import { TitleGroup } from '@/components/TitleGroup';
import { isEqual } from 'lodash';

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string | number;
  dependency_name: string;
  dependency_id: string;
  type: string;
  dependency_detail: string;
  deadline: string;
  data_source: string;
  meets_expectation: string | null;
  is_correct_dependency_name: string | null;
  is_correct_type: string | null;
  is_correct_dependency_detail: string | null;
  is_correct_deadline: string | null;
  note: string | null;
  is_no_deadline: boolean;
}

type DataIndexSearch = keyof Item;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EditableRow: React.FC<{ index: number }> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell: React.FC<{
  // title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleSave: (record: Item) => void;
  // isDisabled?: boolean; // Add isDisabled prop
  isShowTooltip?: boolean;
}> = ({
  // title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  isShowTooltip = false,
  // isDisabled = false, // Default to false

  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;
  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    // if (!isDisabled) {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    // }
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <EditableWrap
        // style={{ paddingRight: 24, width: '100%', minHeight: 22 }}
        onClick={toggleEdit}
        title={
          record && record[dataIndex] ? String(record[dataIndex]) : undefined
        }
      >
        {children}
      </EditableWrap>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const EvaluatePageView: React.FC = () => {
  useProject();
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Data Export');
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const { project_id } = useParams<{ project_id: string }>();
  const [dataSource, setDataSource] = useState<Item[]>([]);
  const [initialData, setInitialData] = useState<Item[]>([]);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const searchInput = useRef<InputRef>(null);
  const tableHeight = useCalculateDynamicHeight(390);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const { selectedProject } = useAuthStore();
  interface EditableColumnType<T> extends ColumnType<T> {
    editable?: boolean;
    isShowTooltip?: boolean;
  }
  const { data: dataEvaluateList, isFetching: isEvaluateListFetching } =
    useQuery({
      queryKey: ['getEvaluateList', project_id],
      queryFn: () => getEvaluateListService(project_id as string),
      enabled: !!project_id, // Only enable the query if selectedProject is defined
    });

  const updateEvaluate = useMutation({
    mutationFn: ({
      project_id,
      data,
    }: {
      project_id: string;
      data: IEvaluateUpdate[];
    }) => submitEvaluateService(project_id, data),
    onSuccess: () => {
      message.success('Evaluate list has been updated successfully.');
      queryClient.invalidateQueries({ queryKey: ['getEvaluateList'] });
      setIsOpenModal(true);
    },
    onError: (err) => {
      // setIsLoading(false);
      message.error(err.message);
    },
  });

  const saveEvaluate = useMutation({
    mutationFn: ({
      project_id,
      data,
    }: {
      project_id: string;
      data: IEvaluateUpdate[];
    }) => saveEvaluateService(project_id, data),
    onSuccess: () => {
      message.success('Save evaluate list successfully.');
      queryClient.invalidateQueries({ queryKey: ['getEvaluateList'] });
    },
    onError: (err) => {
      // setIsLoading(false);
      message.error(err.message);
    },
  });

  // console.log('dataEvaluateList', project_id, dataEvaluateList);
  // const [count, setCount] = useState(1);

  // const handleDelete = (key: React.Key) => {
  //   const newData = dataSource.filter((item) => item.key !== key);
  //   setDataSource(newData);
  // };

  // const handleAdd = () => {
  //   const newData: Item = {
  //     key: count.toString(),
  //     dependency_name: `Dependency ${count}`,
  //     type: 'type B',
  //     dependency_detail: `Detail ${count}`,
  //     deadline: '2023-12-31',
  //     meets_expectation: '0',
  //     is_correct_dependency_name: '1',
  //     is_correct_type: '1',
  //     is_correct_dependency_detail: '0',
  //     is_correct_deadline: '', // Set to empty string instead of null
  //     note: '',
  //   };
  //   setDataSource([...dataSource, newData]);
  //   setCount(count + 1);
  // };

  const handleSave = (row: Item) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    const updatedRow = { ...row };

    newData.splice(index, 1, { ...item, ...updatedRow });
    setDataSource(newData);
  };

  const onSubmit = (isSubmit: boolean = true) => {
    console.log('dataSource', dataSource);
    const payloadData = dataSource.map((item) => {
      return {
        dependency_id: item.dependency_id,
        evaluate_result: {
          meets_expectation: item.meets_expectation
            ? Number(item.meets_expectation)
            : null,
          is_correct_dependency_name: item.is_correct_dependency_name
            ? Number(item.is_correct_dependency_name)
            : null,
          is_correct_type: item.is_correct_type
            ? Number(item.is_correct_type)
            : null,
          is_correct_dependency_detail: item.is_correct_dependency_detail
            ? Number(item.is_correct_dependency_detail)
            : null,
          is_correct_deadline: item.is_correct_deadline
            ? Number(item.is_correct_deadline)
            : null,
          note: item.note ? item.note : '',
        },
      };
    });
    if (project_id) {
      isSubmit
        ? updateEvaluate.mutate({ project_id: project_id, data: payloadData })
        : saveEvaluate.mutate({ project_id: project_id, data: payloadData });
    }
  };

  const handleSearch = (
    // selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    // dataIndex: DataIndexSearch,
  ) => {
    confirm();
  };

  const handleReset = (
    clearFilters: () => void,
    confirm: FilterDropdownProps['confirm'],
    close: () => void,
  ) => {
    clearFilters();
    confirm();
    close();
  };

  const getColumnSearchProps = (
    dataIndex: DataIndexSearch,
  ): ColumnType<Item> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] as any}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(
              // selectedKeys as string[],
              confirm,
              // dataIndex
            )
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(
                // selectedKeys as string[],
                confirm,
                // dataIndex
              )
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters, confirm, close)
            }
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes((value as string).toLowerCase()) as boolean,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    // render: (text) =>
    //   searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //       searchWords={[searchText]}
    //       autoEscape
    //       textToHighlight={text ? text.toString() : ''}
    //     />
    //   ) : (
    //     text
    //   ),
  });

  const handleSelectChange = (
    value: string,
    key: number | string,
    dataIndex: keyof Item,
  ) => {
    setDataSource((prevData) =>
      prevData.map((item) => {
        if (item.key === key) {
          if (dataIndex === 'meets_expectation' && value === '0') {
            // When meets_expectation is "0", disable and reset specific fields to null
            return {
              ...item,
              meets_expectation: value,
              is_correct_dependency_name: null,
              is_correct_type: null,
              is_correct_dependency_detail: null,
              is_correct_deadline: null,
            };
          } else if (dataIndex === 'meets_expectation' && value === '1') {
            return {
              ...item,
              meets_expectation: value,
              is_correct_dependency_name: '1',
              is_correct_type: '1',
              is_correct_dependency_detail: '1',
              is_correct_deadline: '1',
            };
          }
          return { ...item, [dataIndex]: value };
        }
        return item;
      }),
    );
  };

  const renderEditableCell = (
    value: any,
    record: Item,
    dataIndex: keyof Item,
  ) => {
    const isDisabled =
      (record.meets_expectation === '0' || record.meets_expectation === null) &&
      (dataIndex === 'is_correct_dependency_name' ||
        dataIndex === 'is_correct_type' ||
        dataIndex === 'is_correct_dependency_detail' ||
        dataIndex === 'is_correct_deadline');

    return (
      <Select
        value={value}
        onChange={(newValue) =>
          handleSelectChange(newValue, record.key, dataIndex)
        }
        disabled={isDisabled}
        style={{ width: '100%' }}
      >
        <Select.Option value="0">False</Select.Option>
        <Select.Option value="1">True</Select.Option>
      </Select>
    );
  };

  const columns: EditableColumnType<Item>[] = [
    {
      title: 'Dependency Name',
      dataIndex: 'dependency_name',
      width: 350,
      ...getColumnSearchProps('dependency_name'),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: 100,
      render: (value) => (
        <span style={{ textTransform: 'capitalize' }}>{value}</span>
      ),
      sorter: (a, b) => a.type.localeCompare(b.type),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Dependency Detail',
      dataIndex: 'dependency_detail',
      width: 400,
      ...getColumnSearchProps('dependency_detail'),
    },
    {
      title: 'Data Source',
      dataIndex: 'data_source',
      width: 200,
      // ellipsis: true,
      render: (value) => (
        <div
          style={{
            textTransform: 'capitalize',
            width: '150px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          title={value}
        >
          {value}
        </div>
      ),
      ...getColumnSearchProps('data_source'),
    },
    {
      title: 'Deadline',
      dataIndex: 'deadline',
      width: 150,
      render: (value, record) => (
        <span>{record.is_no_deadline ? 'No Deadline' : formatDate(value)}</span>
      ),
      sorter: (a, b) => getUnixTime(a.deadline) - getUnixTime(b.deadline),
    },
    {
      title: 'Meets Expectation',
      dataIndex: 'meets_expectation',
      width: 150,
      render: (value, record) =>
        renderEditableCell(value, record, 'meets_expectation'),
    },
    {
      title: 'Is Correct Dependency Name',
      dataIndex: 'is_correct_dependency_name',
      width: 150,
      render: (value, record) =>
        renderEditableCell(value, record, 'is_correct_dependency_name'),
    },
    {
      title: 'Is Correct Type',
      dataIndex: 'is_correct_type',
      width: 150,
      render: (value, record) =>
        renderEditableCell(value, record, 'is_correct_type'),
    },
    {
      title: 'Is Correct Dependency Detail',
      dataIndex: 'is_correct_dependency_detail',
      width: 150,
      render: (value, record) =>
        renderEditableCell(value, record, 'is_correct_dependency_detail'),
    },
    {
      title: 'Is Correct Deadline',
      dataIndex: 'is_correct_deadline',
      width: 150,
      render: (value, record) =>
        renderEditableCell(value, record, 'is_correct_deadline'),
    },
    {
      title: 'Note',
      dataIndex: 'note',
      width: 200,
      editable: true,
      isShowTooltip: true,
    },
  ];

  const transformField = (value: string | null) => {
    if (value === '1') return 'True';
    if (value === '0') return 'False';
    return '';
  };

  const handleExport = async () => {
    // Define columns
    worksheet.columns = [
      // { header: 'No', key: 'key', width: 5 },
      { header: 'Dependency Name', key: 'dependency_name', width: 50 },
      { header: 'Type', key: 'type', width: 15 },
      { header: 'Dependency Detail', key: 'dependency_detail', width: 70 },
      { header: 'Data Source', key: 'data_source', width: 50 },
      { header: 'Deadline', key: 'deadline', width: 15 },
      { header: 'Meets Expectation', key: 'meets_expectation', width: 18 },
      {
        header: 'Is Correct Dependency Name',
        key: 'is_correct_dependency_name',
        width: 25,
      },
      { header: 'Is Correct Type', key: 'is_correct_type', width: 15 },
      {
        header: 'Is Correct Dependency Detail',
        key: 'is_correct_dependency_detail',
        width: 30,
      },
      { header: 'Is Correct Deadline', key: 'is_correct_deadline', width: 20 },
      { header: 'Note', key: 'note', width: 30 },
    ];

    // Set header font style to bold
    worksheet.getRow(1).font = { bold: true };

    // Add data rows
    dataSource.forEach((item) => {
      worksheet.addRow({
        ...item,
        meets_expectation: transformField(item.meets_expectation),
        is_correct_dependency_name: transformField(
          item.is_correct_dependency_name,
        ),
        is_correct_type: transformField(item.is_correct_type),
        is_correct_dependency_detail: transformField(
          item.is_correct_dependency_detail,
        ),
        is_correct_deadline: transformField(item.is_correct_deadline),
      });
    });

    // Create buffer and save file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    console.log('selectedProject', selectedProject);
    saveAs(
      blob,
      `${selectedProject?.project_code ? selectedProject?.project_code : 'evaluate-data'}.xlsx`,
    );
  };

  const mergedColumns = columns.map((col) => ({
    ...col,
    onCell: (record: Item): any => ({
      record,
      editable: col.editable,
      dataIndex: col.dataIndex,
      // title: col.title,
      handleSave,
      isShowTooltip: col.isShowTooltip,
      // isDisabled:
      //   (record.meets_expectation === '0' || record.meets_expectation === '') &&
      //   (col.dataIndex === 'is_correct_dependency_name' ||
      //     col.dataIndex === 'is_correct_type' ||
      //     col.dataIndex === 'is_correct_dependency_detail' ||
      //     col.dataIndex === 'is_correct_deadline' ||
      //     col.dataIndex === 'note'),
    }),
  }));

  useEffect(() => {
    if (dataEvaluateList) {
      const dataFormat: Item[] =
        dataEvaluateList &&
        dataEvaluateList?.data &&
        dataEvaluateList?.data?.map((item: IEvaluateItem) => {
          const meetsExpectation =
            item.evaluate_result.meets_expectation === null
              ? null
              : item.evaluate_result.meets_expectation.toString();

          return {
            key: item.specific_id,
            dependency_name: item.dependency_name,
            dependency_id: item.dependency_id,
            type: item.type,
            dependency_detail: item.dependency_detail,
            deadline: item.deadline,
            is_no_deadline: item.is_no_deadline,
            data_source: item.data_source,
            meets_expectation: meetsExpectation,
            is_correct_dependency_name:
              meetsExpectation === '0'
                ? null
                : (
                    item.evaluate_result.is_correct_dependency_name ?? ''
                  ).toString(),
            is_correct_type:
              meetsExpectation === '0'
                ? null
                : (item.evaluate_result.is_correct_type ?? '').toString(),
            is_correct_dependency_detail:
              meetsExpectation === '0'
                ? null
                : (
                    item.evaluate_result.is_correct_dependency_detail ?? ''
                  ).toString(),
            is_correct_deadline:
              meetsExpectation === '0'
                ? null
                : (item.evaluate_result.is_correct_deadline ?? '').toString(),
            note:
              item.evaluate_result.note === null
                ? ''
                : item.evaluate_result.note.toString(),
          } as Item; // Ensure that the return type is Item
        });

      setDataSource(dataFormat); // dataFormat is now explicitly of type Item[]
      setInitialData(dataFormat);
    }
  }, [dataEvaluateList]);

  useEffect(() => {
    if (dataSource && dataSource?.length) {
      const hasNullExpectation = dataSource?.some(
        (item) => item.meets_expectation === null,
      );
      // console.log('hasNullExpectation', hasNullExpectation);
      setIsSubmitDisabled(hasNullExpectation);
      setIsSaveDisabled(isEqual(dataSource, initialData));
    }
  }, [dataSource, initialData]);

  return (
    <div>
      {/* <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
        Add a row
      </Button> */}
      <TitleGroup
        title={`${selectedProject?.project_code ? selectedProject?.project_code : ''} -  Conflict Dependency List`}
        // subTitle="Forecast Vista generated dependencies are listed below. Review and approve appropriate dependencies to continue."
        button={
          <Space>
            <Button
              onClick={handleExport}
              type="primary"
              disabled={!dataSource || !dataSource?.length}
            >
              Export
            </Button>
            <Button
              onClick={() => {
                onSubmit(false);
              }}
              type="primary"
              disabled={!dataSource || !dataSource?.length || isSaveDisabled}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                onSubmit();
              }}
              type="primary"
              disabled={!dataSource || !dataSource?.length || isSubmitDisabled}
            >
              Submit
            </Button>
          </Space>
        }
      />
      <Flex gap="small" justify="space-between" style={{ marginBottom: 16 }}>
        <Space>
          <span>
            {' '}
            <strong>Accuracy:</strong>{' '}
            {dataEvaluateList?.evaluate_result?.correctness?.score
              ? dataEvaluateList?.evaluate_result?.correctness?.score?.toFixed(
                  2,
                )
              : 'N/a'}{' '}
            - (
            {dataEvaluateList?.evaluate_result?.correctness?.raw
              ? dataEvaluateList?.evaluate_result?.correctness?.raw
              : 'N/a'}
            )
          </span>
          <span>
            <strong>Completeness:</strong>{' '}
            {dataEvaluateList?.evaluate_result?.completeness}
          </span>
          <span>
            <strong>Rate:</strong>{' '}
            {dataEvaluateList?.evaluate_result?.label_rate?.score &&
            (dataEvaluateList?.evaluate_result?.label_rate?.score !==
              undefined ||
              dataEvaluateList?.evaluate_result?.label_rate?.score !== null)
              ? dataEvaluateList?.evaluate_result?.label_rate?.score?.toFixed(2)
              : 'N/a'}{' '}
            - (
            {dataEvaluateList?.evaluate_result?.label_rate?.raw
              ? dataEvaluateList?.evaluate_result?.label_rate?.raw
              : 'N/a'}
            )
          </span>
        </Space>
      </Flex>

      <Table
        components={{
          body: {
            row: EditableRow,
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={dataSource}
        columns={mergedColumns}
        rowClassName="editable-row"
        loading={isEvaluateListFetching}
        // pagination={false}
        scroll={{ x: 1200, y: tableHeight }}
      />
      <Modal
        title={'The accuracy and completeness of a dependency'}
        open={isOpenModal}
        // onOk={handleOk}
        onCancel={() => setIsOpenModal(false)}
        // width={680}
        footer={null}
      >
        <h4>
          Accuracy:{' '}
          <span>
            {dataEvaluateList?.evaluate_result?.correctness?.score
              ? dataEvaluateList?.evaluate_result?.correctness?.score?.toFixed(
                  2,
                )
              : 'N/a'}{' '}
            - (
            {dataEvaluateList?.evaluate_result?.correctness?.raw
              ? dataEvaluateList?.evaluate_result?.correctness?.raw
              : 'N/a'}
            )
          </span>
        </h4>
        <h4>
          Completeness:{' '}
          <span>{dataEvaluateList?.evaluate_result?.completeness}</span>
        </h4>
        <h4>
          Rate:{' '}
          <span>
            {dataEvaluateList?.evaluate_result?.label_rate?.score &&
            (dataEvaluateList?.evaluate_result?.label_rate?.score !==
              undefined ||
              dataEvaluateList?.evaluate_result?.label_rate?.score !== null)
              ? dataEvaluateList?.evaluate_result?.label_rate?.score?.toFixed(2)
              : 'N/a'}{' '}
            - (
            {dataEvaluateList?.evaluate_result?.label_rate?.raw
              ? dataEvaluateList?.evaluate_result?.label_rate?.raw
              : 'N/a'}
            )
          </span>
        </h4>

        {/* <p>{JSON.stringify(updateEvaluate.data)}</p> */}
      </Modal>
    </div>
  );
};

export default EvaluatePageView;
