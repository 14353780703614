import { TitleGroup } from '@/components/TitleGroup';
import {
  App,
  Button,
  Input,
  InputRef,
  Modal,
  Space,
  Table,
  TableColumnType,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useRef, useState } from 'react';
// import { ButtonWrap } from './styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  confirmService,
  getDependencyListService,
} from '@/services/DependencyService';
import { IDependency } from '@/types/dependency.type';
import { AddAndEditModal } from '../../components/AddAndEditDependencyModal';
import { EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import { FilterDropdownProps } from 'antd/es/table/interface';
import useProject from '@/hooks/useProject';
import useAuthStore from '@/store/authStore';
import { formatDate, getUnixTime } from '@/utils/checkDate';
import useCalculateDynamicHeight from '@/hooks/useCalculateDynamicHeight';

type DataIndex = keyof IDependency;
export const DependencyListPageView = () => {
  const queryClient = useQueryClient();
  useProject();
  const { selectedProject } = useAuthStore();
  const { project_id } = useParams<{ project_id: string }>();
  const { message } = App.useApp();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dataSelected, setDataSelected] = useState<IDependency | undefined>();
  const [{ confirm }, contextHolder] = Modal.useModal();
  const [selectedRowKeysNeedVerify, setSelectedRowKeysNeedVerify] = useState<
    React.Key[]
  >([]);
  // const [searchText, setSearchText] = useState('');
  // const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const tableHeight = useCalculateDynamicHeight(310);

  const { data: dataNeedVerifyList, isFetching: loadingNeedVerifyList } =
    useQuery({
      queryKey: ['getDependencyList', project_id],
      queryFn: () => getDependencyListService(project_id as string),
      enabled: !!project_id, // Only enable the query if selectedProject is defined
    });

  const rowSelectionNeedVerify = {
    selectedRowKeys: selectedRowKeysNeedVerify,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeysNeedVerify(selectedRowKeys);
    },
    getCheckboxProps: (record: IDependency) => ({
      disabled: record.status === 'confirmed', // Disable checkbox if status is confirmed
    }),
  };

  const onConfirm = useMutation({
    mutationFn: ({
      project_id,
      data,
    }: {
      project_id: string;
      data: React.Key[];
    }) => confirmService(project_id, data),
    onSuccess: () => {
      message.success('The dependency has been confirmed successfully.');
      queryClient.invalidateQueries({ queryKey: ['getDependencyList'] });
      setSelectedRowKeysNeedVerify([]);
    },
    onError: (err) => {
      message.error(err.message);
    },
  });

  const handleSearch = (
    // selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    // dataIndex: DataIndex,
  ) => {
    confirm();
    // setSearchText(selectedKeys[0]);
    // setSearchedColumn(dataIndex);
  };

  const handleReset = (
    clearFilters: () => void,
    confirm: FilterDropdownProps['confirm'],
    close: () => void,
  ) => {
    clearFilters();
    // setSearchText('');
    confirm();
    close();
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
  ): TableColumnType<IDependency> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(
              // selectedKeys as string[],
              confirm,
              // dataIndex
            )
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(
                // selectedKeys as string[],
                confirm,
                // dataIndex
              )
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters, confirm, close)
            }
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      const cellValue = record[dataIndex];
      return cellValue
        ? cellValue
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : false;
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    // render: (text) =>
    //   searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //       searchWords={[searchText]}
    //       autoEscape
    //       textToHighlight={text ? text.toString() : ''}
    //     />
    //   ) : (
    //     text
    //   ),
  });

  const columns: ColumnsType<IDependency> = [
    {
      title: 'ID',
      dataIndex: 'specific_id',
      // render: (_, record) => {
      //   const originalIndex = dataNeedVerifyList?.data.findIndex(
      //     (item) => item._id === record._id,
      //   );
      //   return originalIndex !== undefined ? originalIndex + 1 : 0;
      // },
      width: 70,
    },
    {
      title: 'Dependency Name',
      dataIndex: 'dependency_name',
      width: 366,
      ...getColumnSearchProps('dependency_name'),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: 100,
      render: (value) => (
        <span style={{ textTransform: 'capitalize' }}>{value}</span>
      ),
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: 'Dependency Details',
      dataIndex: 'dependency_detail',
      width: 400,
      ...getColumnSearchProps('dependency_detail'),
    },
    {
      title: 'Data Source',
      dataIndex: 'data_source',
      width: 150,
      // ellipsis: true,
      render: (value) => (
        <div
          style={{
            textTransform: 'capitalize',
            width: '150px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          title={value}
        >
          {value}
        </div>
      ),
      ...getColumnSearchProps('data_source'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 150,
      render: (value) => (
        <span style={{ textTransform: 'capitalize' }}>{value}</span>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: 'Deadline',
      dataIndex: 'deadline',
      width: 150,
      render: (value, record) => (
        <span>{record.is_no_deadline ? 'No Deadline' : formatDate(value)}</span>
      ),

      sorter: (a, b) => getUnixTime(a.deadline) - getUnixTime(b.deadline),
    },
    {
      title: 'Generated Date',
      dataIndex: 'created_at',
      width: 200,
      render: (value) => <span>{dayjs(value).format('DD/MM/YYYY')}</span>,
      sorter: (a, b) => getUnixTime(a.created_at) - getUnixTime(b.created_at),
    },
    {
      title: 'Action',
      key: 'action',
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <EditOutlined onClick={() => onEditDependency(record)} />
          {/* <PopoverConfirm
            dataId={record.dependency_id}
            callBack={(id) => handleDelete(id)}
          /> */}
        </Space>
      ),
    },
  ];

  const onEditDependency = (data: IDependency) => {
    setDataSelected(data);
    setIsOpen(true);
  };

  const showConfirm = () => {
    confirm({
      title: 'Confirm the action',
      // icon: m,
      content: 'Are you sure you would like to confirm these dependencies?',
      onOk() {
        project_id &&
          onConfirm.mutate({
            project_id: project_id,
            data: selectedRowKeysNeedVerify,
          });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const onConfirmHandle = () => {
    showConfirm();
  };

  return (
    <>
      {contextHolder}

      <TitleGroup
        title={`${selectedProject?.project_code ? selectedProject?.project_code : ''} -  Generated Dependencies List`}
        // subTitle="Forecast Vista generated dependencies are listed below. Review and approve appropriate dependencies to continue."
        button={
          <Space>
            <Button
              type="primary"
              onClick={() => {
                onConfirmHandle();
              }}
              disabled={
                selectedRowKeysNeedVerify &&
                selectedRowKeysNeedVerify?.length === 0
              }
            >
              Confirm
            </Button>
            <Button type="primary" onClick={() => setIsOpen(true)}>
              Add New Dependency
            </Button>
          </Space>
        }
      />

      <Table
        rowSelection={rowSelectionNeedVerify}
        columns={columns}
        dataSource={dataNeedVerifyList ? dataNeedVerifyList?.data : []}
        rowKey={'dependency_id'}
        scroll={{ x: 1300, y: tableHeight }}
        loading={loadingNeedVerifyList}
      />

      {/* <ButtonWrap style={{ marginBottom: '24px', marginTop: '16px' }}>
        <Button
          type="primary"
          onClick={() => {
            onConfirmHandle();
          }}
          disabled={
            selectedRowKeysNeedVerify && selectedRowKeysNeedVerify?.length === 0
          }
        >
          Confirm
        </Button>
      </ButtonWrap> */}
      <AddAndEditModal
        isModalOpen={isOpen}
        setIsModalOpen={(data) => {
          setIsOpen(data);
          !data && setDataSelected(undefined);
        }}
        dataEdit={dataSelected}
      />
    </>
  );
};
