import { Menu } from 'antd';
import {
  BookMarked,
  FilePen,
  FileText,
  FolderKanban,
  LayoutDashboard,
  TriangleAlert,
} from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

interface MenuItem {
  key: string;
  label: React.ReactNode;
  icon?: React.ReactNode;
  children?: MenuItem[];
}

const MainMenu = ({ onChangeKey }: { onChangeKey?: () => void }) => {
  const { pathname } = useLocation();
  const pathSegments = pathname.split('/').filter(Boolean);
  const selectedKey = pathSegments[pathSegments?.length - 1] || 'home';
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const { project_id } = useParams<{ project_id: string }>();
  const menuData = [
    {
      key: 'projects',
      label: (
        <Link to="/projects" style={{ cursor: 'pointer' }}>
          Projects List
        </Link>
      ),
      icon: <FolderKanban />,
    },
    {
      key: 'documents',
      label: (
        <Link
          to={`/projects/${project_id}/documents`}
          style={{ cursor: 'pointer' }}
        >
          Documents
        </Link>
      ),
      icon: <FileText />,
    },
    {
      key: 'risks',
      label: (
        <Link
          to={`/projects/${project_id}/risks`}
          style={{ cursor: 'pointer' }}
        >
          Risk symptom/ Issue
        </Link>
      ),
      icon: <TriangleAlert />,
    },
    {
      key: 'evaluate',
      label: (
        <Link
          to={`/projects/${project_id}/evaluate`}
          style={{ cursor: 'pointer' }}
        >
          Evaluate
        </Link>
      ),
      icon: <FilePen />,
    },
    {
      key: 'dependency',
      label: 'Dependency',
      icon: <LayoutDashboard />,
      children: [
        {
          key: 'dependency-list',
          label: (
            <Link
              to={`/projects/${project_id}/dependency/dependency-list`}
              style={{ cursor: 'pointer' }}
            >
              Dependency List
            </Link>
          ),
        },
        {
          key: 'confirmed-dependency',
          label: (
            <Link
              to={`/projects/${project_id}/dependency/confirmed-dependency`}
              style={{ cursor: 'pointer' }}
            >
              Confirmed Dependency
            </Link>
          ),
        },
        {
          key: 'conflict-confirmation',
          label: (
            <Link
              to={`/projects/${project_id}/dependency/conflict-confirmation`}
              style={{ cursor: 'pointer' }}
            >
              Conflict Confirmation
            </Link>
          ),
        },
      ],
    },
    {
      key: 'userGuide',
      label: 'User Guide',
      icon: <BookMarked />,
      children: [
        {
          key: 'userGuideDocument',
          label: 'Document',
          url: 'https://fptsoftware362-my.sharepoint.com/:b:/g/personal/huongns_fpt_com/EajP1tuKctdMtbsKoLhCMbcBvfZezhHiSRRQBtxYYJUjfA?e=IGjqtt',
        },
        {
          key: 'videoDemo',
          label: 'Video Demo',
          url: 'https://fptsoftware362-my.sharepoint.com/:v:/g/personal/huongns_fpt_com/EYpon-cqnBJIjFf9YD6FXyEBaPN_I2Wj026tBbxe0oer1g?nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJPbmVEcml2ZUZvckJ1c2luZXNzIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXciLCJyZWZlcnJhbFZpZXciOiJNeUZpbGVzTGlua0NvcHkifX0&e=At6c1z',
        },
      ],
    },
    // Add more top-level menu items as needed
  ];

  const onOpenChange = (keys: string[]) => {
    setOpenKeys(keys);
    console.log('keys', keys);
  };

  const findOpenKeys = (data: MenuItem[]) => {
    for (let item of data) {
      if (item.children) {
        const found = item.children.some((child) => {
          return child.key === selectedKey;
        });
        if (found) {
          setOpenKeys((openKeys) => [...openKeys, item.key]);
          break;
        }
      }
    }
  };

  const findItemByKey = (items: MenuItem[], key: string): any | undefined => {
    for (const item of items) {
      if (item?.key === key) return item;
      if (item?.children) {
        const found = findItemByKey(item.children, key);
        if (found) return found;
      }
    }
  };

  const handleMenuClick = (e: { key: string }) => {
    const item = findItemByKey(menuData, e.key);
    if (item && 'url' in item && typeof item.url === 'string') {
      window.open(item.url, '_blank'); // Open items with 'url' in a new tab
    }
  };

  useEffect(() => {
    findOpenKeys(menuData as MenuItem[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKey]);

  return (
    <Menu
      mode="inline"
      style={{ height: '100%', borderRight: 0 }}
      selectedKeys={[selectedKey]}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      onSelect={() => onChangeKey && onChangeKey()}
      items={menuData}
      onClick={handleMenuClick}
    />
  );
};
export default React.memo(MainMenu);
