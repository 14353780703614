import { Input, List } from 'antd';
import { ProjectsItem } from './components/ProjectItem';
import { IProject } from '@/types/project.type';
import { useQuery } from '@tanstack/react-query';
import { getProjectListService } from '@/services/ProjectService';
import useAuthStore from '@/store/authStore';
import { useNavigate } from 'react-router-dom';
import { NoSidebarLayout } from '@/layout/NoSidebarLayout';
import { Container } from '@/styles/globalStyles';
import React, { useEffect, useState } from 'react';
import { useDebounce } from '@uidotdev/usehooks';

export const ProjectsPageView = () => {
  const { user } = useAuthStore();
  const navigate = useNavigate();
  const userID = user?.user_id;
  // const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [filteredData, setFilteredData] = useState<IProject[]>([]);
  const {
    data: dataProjectList,
    isFetching: loadingDataProjectList,
    isFetched,
  } = useQuery({
    queryKey: ['getProjectList'],
    queryFn: () => getProjectListService(userID as string),
    // queryFn: () => {
    //   return {
    //     success: true,
    //     message: null,
    //     data: [],
    //     retry: false,
    //   };
    // },
  });

  const goToDocument = (id: string) => {
    navigate(`/projects/${id}/documents`);
  };

  // eslint-disable-next-line no-undef
  useEffect(() => {
    const filtered =
      dataProjectList &&
      (dataProjectList?.data?.filter((item: IProject) =>
        item.project_code
          .toLowerCase()
          .includes(debouncedSearchTerm.toLowerCase()),
      ) as IProject[]);

    setFilteredData(filtered ? filtered : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]); // Only updates when debounced term changes

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    setFilteredData(
      dataProjectList && dataProjectList?.data ? dataProjectList?.data : [],
    );
  }, [dataProjectList]);

  return (
    <NoSidebarLayout>
      <Container style={{ marginBottom: '40px', marginTop: '40px' }}>
        <Input.Search
          placeholder="Search projects"
          value={searchTerm}
          onChange={onSearchChange}
          style={{ marginBottom: 16 }}
          width={'50%'}
        />
        <List
          grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 4, xl: 4, xxl: 4 }}
          dataSource={filteredData}
          loading={loadingDataProjectList}
          pagination={{
            onChange: (page) => {
              console.log(page);
            },
            position: 'bottom',
            align: 'center',
            pageSize: 8,
          }}
          renderItem={(item: IProject) => (
            <List.Item>
              <ProjectsItem itemProject={item} goToDocument={goToDocument} />
            </List.Item>
          )}
          locale={{
            emptyText:
              isFetched &&
              dataProjectList &&
              dataProjectList?.data?.length === 0 ? (
                <div style={{ textAlign: 'center', color: '#000000' }}>
                  <p>You have not been added to any projects.</p>
                  <p>
                    Please contact the Project Manager for confirmation and
                    support.
                  </p>
                  <p>
                    Additionally, you can reach out to the AIC support team for
                    assistance at cuongld9@fpt.com.
                  </p>
                </div>
              ) : (
                <div style={{ textAlign: 'center', color: '#000000' }}>
                  <p>No results match the search criteria.</p>
                </div>
              ),
          }}
        />
      </Container>
    </NoSidebarLayout>
  );
};
