import { TitleGroup } from '@/components/TitleGroup';
import useAuthStore from '@/store/authStore';
import {
  GridContainer,
  ProgressCircleItem,
  StyledCol,
  StyledRow,
} from './styles';
import themeConfigs from '@/config/themeConfigs';
import { Col, Progress, Row } from 'antd';

export const OverviewPageView = () => {
  const { selectedProject } = useAuthStore();

  const dataSource = [
    { key: '1', value: 'John Brown' },
    { key: '2', value: '32' },
    { key: '3', value: 'New York No. 1 Lake Park' },
    { key: '4', value: 'Engineer' },
    { key: '5', value: '2023-07-15' },
    { key: '2', value: '32' },
    { key: '3', value: 'New York No. 1 Lake Park' },
    { key: '4', value: 'Engineer' },
    { key: '5', value: '2023-07-15' },
  ];

  const mockArray = [
    { value: 100, name: 'Design', status: 'Completed' },
    { value: 100, name: 'Plan', status: 'Completed' },
    { value: 75, name: 'Develop', status: 'In progress' },
    { value: 0, name: 'Test', status: 'Waiting' },
  ];
  // Calculate how many empty columns we need to add
  const emptyColumnsCount = (4 - (dataSource.length % 4)) % 4;

  // Create an array of empty columns
  const emptyColumns = Array(emptyColumnsCount)
    .fill(null)
    .map((_, index) => ({
      key: `empty-${index}`,
      value: '',
    }));

  // Combine the original data with empty columns
  const fullDataSource = [...dataSource, ...emptyColumns];

  return (
    <div>
      <TitleGroup
        title={`${selectedProject?.project_code ? selectedProject?.project_code : ''} -  Overview Page`}
        // subTitle="Project A’s documents are listed as below. User can update documents to generate risks. "
      />
      <GridContainer>
        <StyledRow>
          {fullDataSource.map((item, index) => {
            const isLastRow = index >= fullDataSource.length - 4;
            const isLastFourColumns = index >= fullDataSource.length - 4;

            return (
              <StyledCol
                key={item.key}
                isLastRow={isLastRow}
                isLastFourColumns={isLastFourColumns}
              >
                {item.value}
              </StyledCol>
            );
          })}
        </StyledRow>
      </GridContainer>
      <p>
        Goals: Implement a CRM system to enhance customer relationship
        management, improve sales efficiency, and increase customer
        satisfaction. Scope: The project will focus on selecting, implementing,
        and integrating a CRM system with existing systems. Deliverables: Fully
        implemented CRM system, data migration, user training, and system
        documentation.
      </p>
      <h4>Process state</h4>
      <Progress
        percent={60}
        // percentPosition={{ align: 'end', type: 'inner' }}
        size={['100%', 50]}
        strokeColor={themeConfigs.primaryColor}
        format={(percent) => `${percent}%`}
        strokeLinecap="butt"
      />
      <div style={{ maxWidth: '1000px', margin: '24px auto' }}>
        <Row gutter={[16, 16]}>
          {mockArray.map((data, index) => {
            return (
              <Col span={6} key={index}>
                <ProgressCircleItem>
                  <span className="title">{data.name}</span>
                  <div className="ProgressCircleWrap">
                    <Progress
                      strokeLinecap="butt"
                      type="circle"
                      percent={data.value}
                      format={(percent) => `${percent} %`}
                      strokeColor={themeConfigs.primaryColor}
                    />
                  </div>
                  <span className="status">{data.status}</span>
                </ProgressCircleItem>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};
