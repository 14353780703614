import useAuthStore from '@/store/authStore';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
  const { user } = useAuthStore();

  return user ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
