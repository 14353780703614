import { ReactNode } from 'react';
import { TitleGroupStyle } from './styles';
export const TitleGroup = ({
  title,
  subTitle,
  button,
}: {
  title: string;
  subTitle?: string;
  button?: ReactNode;
}) => {
  return (
    <TitleGroupStyle>
      <div>
        <h3>{title}</h3>
        <p>{subTitle}</p>
      </div>
      {button && <div>{button}</div>}
    </TitleGroupStyle>
  );
};
