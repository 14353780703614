import styled from 'styled-components';

export const ItemWrap = styled.div`
  margin-bottom: 4px;
`;
export const ItemText = styled.span`
  font-size: 16px;
  font-weight: 600;
  /* margin-left: 10px; */
  &:only-child {
    margin-left: 0;
  }
`;
