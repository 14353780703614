export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_ADB2C_CLIENT_ID || '',
    authority: process.env.REACT_APP_ADB2C_AUTHORITY || '',
    redirectUri: `${window.location.origin}/`,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

export const loginRequest = {
  scopes: ['api://forecast-vista/fv.scope'],
};
