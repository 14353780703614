import { ChartFeedBackComponent } from '@/components/ChartFeedBackComponent';
import themeConfigs from '@/config/themeConfigs';
import {
  getFeedbackIntentService,
  getFeedbackMessageService,
  getFeedbackUserService,
  getMessageDailyOfProjectService,
} from '@/services/FeedbackService';
import { dateFormatterForChart } from '@/utils/dateFormatterForChart';
import { transformDataChart } from '@/utils/transformDataChart';
import { useQuery } from '@tanstack/react-query';
import { Button, Card, Empty, List, Select, Skeleton, Space, Tag } from 'antd';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip as TooltipChart,
  Legend,
  Bar,
} from 'recharts';

export const FeedbackPageView = () => {
  const { Option } = Select;
  const { project_id } = useParams<{ project_id: string }>();
  const [duration, setDuration] = useState('7'); // Default duration
  const navigate = useNavigate();

  const { data: dataFeedbackIntent, isFetching: isFeedbackIntentFetching } =
    useQuery({
      queryKey: ['getFeedbackIntent', project_id, duration],
      queryFn: () => getFeedbackIntentService(project_id as string, duration),
      enabled: !!project_id, // Only enable the query if selectedProject is defined
    });

  const { data: getFeedbackUser, isFetching: isFeedbackUserFetching } =
    useQuery({
      queryKey: ['getFeedbackUserService', project_id, duration],
      queryFn: () => getFeedbackUserService(project_id as string, duration),
      enabled: !!project_id, // Only enable the query if selectedProject is defined
    });

  const { data: dataMessageDaily, isFetching: isMessageDailyFetching } =
    useQuery({
      queryKey: ['getMessageDailyService', project_id, duration],
      queryFn: () =>
        getMessageDailyOfProjectService(project_id as string, duration),
      enabled: !!project_id,
    });

  const { data: getFeedbackMessage, isFetching: isFeedbackMessageFetching } =
    useQuery({
      queryKey: ['getFeedbackMessageService', project_id, duration],
      queryFn: () => getFeedbackMessageService(project_id as string, duration),
      enabled: !!project_id, // Only enable the query if selectedProject is defined
      select(data) {
        return Object.entries(data).map(([user, message]) => ({
          user,
          message,
        })) as {
          user: string;
          message: number;
        }[];
      },
    });

  const handleDurationChange = (value: string) => {
    setDuration(value); // Update the duration when a new option is selected
  };
  // Transform the data to be compatible with rechart
  return (
    <>
      {isFeedbackIntentFetching ||
      isFeedbackUserFetching ||
      isFeedbackMessageFetching ||
      isMessageDailyFetching ? (
        <Skeleton active={true} />
      ) : (
        <div>
          <Space>
            <Select
              value={duration}
              style={{ width: 120 }}
              onChange={handleDurationChange}
            >
              <Option value="3">3 Days</Option>
              <Option value="7">7 Days</Option>
              <Option value="15">15 Days</Option>
              <Option value="30">30 Days</Option>
            </Select>
            <Button type="link" onClick={() => navigate(`/all-feedback`)}>
              View all feed back
            </Button>
          </Space>

          {dataFeedbackIntent && dataFeedbackIntent.length > 0 ? (
            dataFeedbackIntent?.map((item, index) => (
              <ChartFeedBackComponent
                key={index}
                title={item.intent}
                chartData={transformDataChart(item.data)}
              />
            ))
          ) : (
            <>
              <h2>Intent</h2>
              <Empty />
            </>
          )}
          {getFeedbackUser &&
          getFeedbackUser.user &&
          getFeedbackUser.user.length > 0 ? (
            <ChartFeedBackComponent
              title={'User'}
              chartData={transformDataChart(getFeedbackUser.user)}
            />
          ) : (
            <>
              {' '}
              <h2>User</h2>
              <Empty />
            </>
          )}

          <h2>
            <Space align="center">
              <span>Message</span>
              <Tag
                color={themeConfigs.primaryColor}
                style={{ verticalAlign: 'middle' }}
              >
                {/* {totalCounts.like + totalCounts.dislike + totalCounts.other} */}
                {dataMessageDaily && dataMessageDaily?.length
                  ? dataMessageDaily.reduce(
                      (acc: any, entry: { num_messages: any }) =>
                        acc + entry.num_messages,
                      0,
                    )
                  : 0}
              </Tag>
            </Space>
          </h2>
          {dataMessageDaily && dataMessageDaily.length > 0 ? (
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                // width={500}
                // height={300}
                data={dataMessageDaily}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" tickFormatter={dateFormatterForChart} />
                <YAxis allowDecimals={false} />
                <TooltipChart />
                <Legend />
                <Bar
                  dataKey="num_messages"
                  fill={themeConfigs.primaryColor}
                  // activeBar={<Rectangle fill="pink" stroke="blue" />}
                />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <>
              <Empty />
            </>
          )}

          <div>
            <h2>
              <Space align="center">
                <span>Message of user</span>

                <Tag
                  color={themeConfigs.primaryColor}
                  style={{ verticalAlign: 'middle' }}
                >
                  {/* {totalCounts.like + totalCounts.dislike + totalCounts.other} */}
                  {getFeedbackMessage && getFeedbackMessage?.length
                    ? getFeedbackMessage.reduce(
                        (acc, entry) => acc + entry.message,
                        0,
                      )
                    : 0}
                </Tag>
              </Space>
            </h2>

            <List
              grid={{
                gutter: 16,
                xs: 1,
                sm: 2,
                md: 4,
                lg: 4,
                xl: 6,
                xxl: 3,
              }}
              dataSource={getFeedbackMessage ? getFeedbackMessage : []}
              renderItem={(item) => (
                <List.Item>
                  <Card title={item.user}>
                    Number of messages: {<b>{item.message}</b>}
                  </Card>
                </List.Item>
              )}
              pagination={{
                // onChange: (page) => setCurrent(page),
                pageSize: 6,
                // total: getFeedbackMessage ? getFeedbackMessage.length : 0,
                // showSizeChanger: false, // Optional: hide size changer
              }}
              locale={{
                emptyText: <Empty />,
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
