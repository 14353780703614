import { useEffect, useState } from 'react';

const useCalculateDynamicHeight = (offsetHeight: number = 300): number => {
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    const calculateHeight = () => {
      const viewportHeight = window.innerHeight;
      setHeight(viewportHeight - offsetHeight);
    };

    calculateHeight();
    window.addEventListener('resize', calculateHeight);

    return () => window.removeEventListener('resize', calculateHeight);
  }, [offsetHeight]);

  return height;
};

export default useCalculateDynamicHeight;
