import useAuthStore from '../../store/authStore';
// import { useNavigate } from 'react-router-dom';
import {
  LoginDesc,
  LoginLeft,
  LoginRight,
  LoginTitle,
  LoginWrap,
} from './styles';
import { useMutation } from '@tanstack/react-query';
import { IDataLogin } from '@/types/auth.type';
import { loginService } from '@/services/AuthService';
import { App, Button } from 'antd';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '@/config/msalConfig';
import { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

export const LoginPageView = () => {
  // const navigate = useNavigate();
  const { login } = useAuthStore();
  const { message } = App.useApp();
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // call sever login
  const onLogin = useMutation({
    mutationFn: (params: IDataLogin) => loginService(params),
    onSuccess: (data) => {
      message.success('Login was successful.');
      const dataUser = {
        access_token: data.access_token,
        email: data.email,
        user_name: data.user_name,
        user_id: data.user_id,
      };
      login(dataUser);
      setIsLoading(false);
    },
    onError: (err) => {
      message.error(err.message);
      setIsLoading(false);
    },
  });

  const handleLogin = async () => {
    const dataAzure = await instance
      .loginPopup({
        ...loginRequest,
        redirectUri: `${window.location.origin}/blank.html`,
      })
      .catch((e: any) => {
        console.error(e);
      });

    dataAzure?.accessToken && onLogin.mutate({ token: dataAzure?.accessToken });
    // Use the login function from the store to update the authentication status
    // login(user);
    // navigate('/', { replace: true });
    setIsLoading(true);
  };
  return (
    <LoginWrap>
      {/* <LoginForm>
        <LoginBackgroundForm>
          <LoginTitle>Login</LoginTitle>
          <LoginDesc>Lorem Ipsum is simply dummy text</LoginDesc>
          <LoginButton size="large" onClick={handleLogin} type="primary">
            Sign in with your FSOFT account
          </LoginButton>
        </LoginBackgroundForm>
      </LoginForm> */}
      <LoginLeft>
        <LoginTitle>Welcome back!</LoginTitle>
        <LoginDesc>You can login to view details about your project.</LoginDesc>
        <Button size="large" onClick={handleLogin} type="primary">
          Login with SSO {isLoading ? <LoadingOutlined /> : null}
        </Button>
      </LoginLeft>
      <LoginRight />
    </LoginWrap>
  );
};
