import { IChartItem, IChartRes, IChartUserRes } from '@/types/feedback.type';
import { axiosInstance } from '@/utils/axios';

export const getFeedbackIntentService = async (
  id: string,
  duration: string,
): Promise<IChartRes[]> => {
  const response = await axiosInstance.get(
    `/project/${id}/feedback/intent/?duration=${duration}`,
  );
  return response.data?.data;
};

export const getFeedbackUserService = async (
  id: string,
  duration: string,
): Promise<IChartUserRes> => {
  const response = await axiosInstance.get(
    `/project/${id}/feedback/user/?duration=${duration}`,
  );
  return response.data?.data;
};

export const getFeedbackMessageService = async (
  id: string,
  duration: string,
): Promise<IChartUserRes> => {
  const response = await axiosInstance.get(
    `/project/${id}/message/?duration=${duration}`,
  );
  return response.data?.data;
};

export const getAllFeedbackService = async (
  duration: string,
): Promise<IChartItem[]> => {
  const response = await axiosInstance.get(`/feedback/?duration=${duration}`);
  return response.data?.data;
};

export const getMessageDailyOfProjectService = async (
  id: string,
  duration: string,
): Promise<any> => {
  const response = await axiosInstance.get(
    `/project/${id}/message/daily/?duration=${duration}`,
  );
  return response.data?.data;
};

export const getMessageDailyService = async (
  duration: string,
): Promise<any> => {
  const response = await axiosInstance.get(
    `/message/daily/?duration=${duration}`,
  );
  return response.data?.data;
};

export const getAllFeedbackMessageService = async (
  duration: string,
): Promise<any> => {
  const response = await axiosInstance.get(`/message/?duration=${duration}`);
  return response.data?.data;
};
