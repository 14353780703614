// theme.js
const themeConfigs = {
  //color
  primaryColor: '#5D7B6F',
  secondaryColor: '#ff4500',
  textColor: '#667085',
  titleColor: '#101828',
  //size
  fontSize: 16,
  borderRadius: 8,
  borderColor: '#EAECF0',
  // Add more variables as needed
};

export default themeConfigs;
