import themeConfigs from '@/config/themeConfigs';
import styled, { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  body {
    padding: 0;
    background-color: #ffffff;
    margin: 0;
    font-family: Inter, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }

  /* Style Modal */
  .ant-modal {
    .ant-modal-header {
      border-radius: 0.5rem 0.5rem 0rem 0rem;
      margin-bottom: 0;
    }

    .ant-modal-title {
      padding: 16px 24px;
      font-size: 18px;
      font-weight: 700;
      line-height: 20.7px;
    }

    .ant-modal-close {
      color: #fff;
    }

    .ant-modal-body {
      padding: 16px 40px;
        
    }

    .ant-modal-content {
      border-radius: 0.5rem;
      padding: 0px;
    }

    .ant-modal-footer {
      padding: 16px 24px;
      border-top: 1px solid ${themeConfigs.borderColor};
    }
  }
  .ant-modal-confirm.ant-modal .ant-modal-body {
    padding: 16px 24px;
  }

  /* menu */
  .ant-menu-light .ant-menu-item-selected {
    background-color: #ECFDF3;
    color: #000000;
    border-radius: 0;
    &:after {
      content: '';
      display: block;
      background-color: ${themeConfigs.primaryColor};
      width: 2px;
      height: 100;
      top:0; 
      right: 0;
    }
  }

  .ant-layout .ant-layout-sider-trigger {
    background-color: ${themeConfigs.primaryColor};
  }

  /* Table select disable */

  .ant-checkbox-disabled,
  .ant-checkbox-disabled .ant-checkbox-input {
    opacity: 0.2;
  }

  .highlight-row {
    background-color: #e6f7ff !important;
  }

`;

export const Row = styled.div<{ $inputSpace?: string }>`
  display: flex;
  flex-wrap: wrap;

  margin-right: ${(props) => props.$inputSpace || '-16px'};
  margin-left: ${(props) => props.$inputSpace || '-16px'};
  & > * {
    padding-right: ${(props) => props.$inputSpace || '16px'};
    padding-left: ${(props) => props.$inputSpace || '16px'};
  }
`;

export const ContainerFluid = styled.div<{ $inputSpace?: string }>`
  width: 100%;
  padding-right: ${(props) => props.$inputSpace || '16px'};
  padding-left: ${(props) => props.$inputSpace || '16px'};
  margin-right: auto;
  margin-left: auto;
`;

export const Container = styled(ContainerFluid)`
  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width: 1400px) {
    max-width: 1320px;
  }
`;
