import { TitleGroup } from '@/components/TitleGroup';
import { getRiskListService } from '@/services/RiskService';
import { IRisk } from '@/types/risk.type';
import { useQuery } from '@tanstack/react-query';
import { Button, Input, InputRef, Space, Table, TableColumnType } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FilterDropdownProps } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import {
  useRef,
  // useState
} from 'react';
import { useParams } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import useProject from '@/hooks/useProject';
import useAuthStore from '@/store/authStore';
import useCalculateDynamicHeight from '@/hooks/useCalculateDynamicHeight';
// import Highlighter from 'react-highlight-words';

type DataIndex = keyof IRisk;
export const RisksPageView = () => {
  const { project_id } = useParams<{ project_id: string }>();
  const searchInput = useRef<InputRef>(null);
  const tableHeight = useCalculateDynamicHeight(320);
  // const [searchText, setSearchText] = useState('');
  // const [searchedColumn, setSearchedColumn] = useState('');
  useProject();
  const { selectedProject } = useAuthStore();
  const { data: dataListRisk, isFetching: loadingListRisk } = useQuery({
    queryKey: ['getListRisk', project_id],
    queryFn: () => getRiskListService(project_id as string),
    enabled: !!project_id, // Only enable the query if selectedProject is defined
  });

  const handleSearch = (
    // selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    // dataIndex: DataIndex,
  ) => {
    confirm();
    // setSearchText(selectedKeys[0]);
    // setSearchedColumn(dataIndex);
  };

  const handleReset = (
    clearFilters: () => void,
    confirm: FilterDropdownProps['confirm'],
    close: () => void,
  ) => {
    clearFilters();
    // setSearchText('');
    confirm();
    close();
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
  ): TableColumnType<IRisk> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(
              // selectedKeys as string[],
              confirm,
              // dataIndex
            )
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(
                // selectedKeys as string[],
                confirm,
                // dataIndex
              )
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters, confirm, close)
            }
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      const cellValue = record[dataIndex];
      return cellValue
        ? cellValue
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : false;
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    // render: (text) =>
    //   searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //       searchWords={[searchText]}
    //       autoEscape
    //       textToHighlight={text ? text.toString() : ''}
    //     />
    //   ) : (
    //     text
    //   ),
  });

  const columns: ColumnsType<IRisk> = [
    {
      title: 'ID',
      dataIndex: 'specific_id',
      // render: (_, record) =>
      //   dataListRisk && dataListRisk.data.indexOf(record) + 1,
      width: 70,
    },
    {
      title: 'Category Area',
      dataIndex: 'category',
      width: 200,
      sorter: (a, b) => a.category.localeCompare(b.category),
    },
    {
      title: 'Risk Symptoms/ Issues Name',
      dataIndex: 'sign_symptoms',
      width: 150,
    },
    {
      title: 'Risk Symptoms/ Issues Details',
      dataIndex: 'symptom_details',
      width: 400,
      render: (value) => <div dangerouslySetInnerHTML={{ __html: value }} />,
      ...getColumnSearchProps('symptom_details'),
    },

    {
      title: 'Type',
      dataIndex: 'type',
      width: 100,
      render: (value) => (
        <span style={{ textTransform: 'capitalize' }}>{value}</span>
      ),
      sorter: (a, b) => a.category.localeCompare(b.category),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 100,
      sorter: (a, b) => a.category.localeCompare(b.category),
    },

    {
      title: 'Risk/ Issue Category',
      dataIndex: 'risk_category',
      width: 150,
      sorter: (a, b) => a.risk_category.localeCompare(b.risk_category),
    },

    {
      title: 'Data Source',
      dataIndex: 'data_source',
      width: 150,
      ...getColumnSearchProps('data_source'),
    },

    {
      title: 'Mitigation Plan',
      dataIndex: 'mitigation_plan',
      width: 150,
    },

    {
      title: 'Generated Date',
      dataIndex: 'generated_date',
      width: 150,
      render: (value) => <span>{dayjs(value).format('DD/MM/YYYY')}</span>,
      // sorter: (a, b) =>
      //   dayjs(a.generated_date).unix() - dayjs(b.generated_date).unix(),
      sorter: (a, b) => {
        const formatDateString = (date: string | dayjs.Dayjs | null) => {
          if (!date) return ''; // Treat null as an empty string
          return typeof date === 'string' ? date : date.format('YYYY-MM-DD');
        };

        const deadlineA = formatDateString(a.generated_date);
        const deadlineB = formatDateString(b.generated_date);
        return deadlineA.localeCompare(deadlineB);
      },
    },
  ];
  return (
    <>
      <TitleGroup
        title={`${selectedProject?.project_name ? selectedProject?.project_name : ''} -  Risk Symptoms / Issues List`}
        // subTitle="Project A’s documents are listed as below. User can update documents to generate risks. "
      />
      <Table
        columns={columns}
        dataSource={dataListRisk ? dataListRisk?.data : []}
        scroll={{ x: 1400, y: tableHeight }}
        rowKey={'_id'}
        loading={loadingListRisk}
      />
    </>
  );
};
