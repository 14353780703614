import { Button, Popover } from 'antd';
import { useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import {
  CustomButtonNo,
  CustomButtonYes,
  SpanDel,
  WrapperButton,
} from '@/components/PopoverConfirm/styles';

const PopoverConfirm = ({
  dataId,
  isDisable,
  callBack,
}: {
  dataId: string;
  // isOpen: boolean;
  isDisable?: boolean;
  callBack?: (param?: any) => void;
  // onDelelte: () => void;
  // handleOpenChange: (newOpen: boolean) => void;
}) => {
  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  const onDeleteRow = () => {
    callBack && callBack(dataId);
    setOpen(false);
  };

  return (
    <Popover
      content={
        <div>
          <SpanDel>Are you sure delete this item?</SpanDel>
          <WrapperButton>
            <CustomButtonNo size="middle" onClick={hide}>
              No
            </CustomButtonNo>
            <CustomButtonYes size="middle" onClick={onDeleteRow}>
              Yes
            </CustomButtonYes>
          </WrapperButton>
        </div>
      }
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottomRight"
    >
      <Button type="text" icon={<DeleteOutlined />} disabled={isDisable} />
      {/* <DeleteOutlined disabled={isDisable} /> */}
    </Popover>
  );
};
export default PopoverConfirm;
