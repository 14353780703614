import {
  createDependencyService,
  updateDependencyService,
} from '@/services/DependencyService';
import { getDocumentListService } from '@/services/DocumentService';
import { IDependency, IDependencyCreate } from '@/types/dependency.type';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  App,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

interface DataSourceOption {
  value: string;
  label: string;
}

const defaultValues = {
  dependency_name: '',
  dependency_detail: '',
  type: 'internal',
  deadline: undefined,
  data_source: undefined,
  other_text: '',
  // Third_Party: '',
  is_confirmed: true,
};

const disabledDate = (current: dayjs.Dayjs) => {
  // Can not select days before today
  return current && current.isBefore(dayjs().startOf('day'));
};

export const AddAndEditModal = ({
  isModalOpen,
  // callback,
  setIsModalOpen,
  dataEdit,
}: {
  isModalOpen: boolean;
  // callback?: () => void;
  setIsModalOpen: (data: boolean) => void;
  dataEdit?: IDependency;
}) => {
  const { TextArea } = Input;
  const [{ confirm }, contextHolder] = Modal.useModal();
  // const { selectedProject } = useAuthStore();
  const { project_id } = useParams<{ project_id: string }>();
  const queryClient = useQueryClient();
  const [form] = Form.useForm<IDependencyCreate>();
  const { message } = App.useApp();
  const { Option } = Select;
  const [dataSources, setDataSources] = useState<DataSourceOption[]>([]);
  const [isOtherSelected, setIsOtherSelected] = useState<boolean>(false);
  const [isNoDeadline, setIsNoDeadline] = useState<boolean>(true);

  const createDependency = useMutation({
    mutationFn: ({
      project_id,
      data,
    }: {
      project_id: string;
      data: IDependencyCreate;
    }) => createDependencyService(project_id, data),
    onSuccess: () => {
      // setIsLoading(false);
      message.success('The dependency has been created successfully.');
      form.resetFields();

      queryClient.invalidateQueries({ queryKey: ['getDependencyList'] });
      queryClient.invalidateQueries({
        queryKey: ['getListDependencyConfirmed'],
      });
      // queryClient.invalidateQueries({
      //   queryKey: ['getListDocument'],
      // });

      setIsOtherSelected(false);
      setIsModalOpen(false);
    },
    onError: (err) => {
      // setIsLoading(false);
      message.error(err.message);
    },
  });

  const { data: dataListDocument, refetch: refetchListDocument } = useQuery({
    queryKey: ['getListDocument', project_id],
    queryFn: () => getDocumentListService(project_id as string),
    enabled: false, // Only enable the query if selectedProject is defined
    select: (data) =>
      data.data.map((doc) => ({
        value: doc.file_name,
        label: doc.file_name,
      })),
  });

  const updateDependency = useMutation({
    mutationFn: ({
      project_id,
      id,
      data,
    }: {
      project_id: string;
      id: string;
      data: IDependencyCreate;
    }) => updateDependencyService(project_id, id, data),
    onSuccess: () => {
      // setIsLoading(false);
      message.success('The dependency has been updated successfully.');
      form.resetFields();

      queryClient.invalidateQueries({ queryKey: ['getDependencyList'] });
      queryClient.invalidateQueries({
        queryKey: ['getListDependencyConfirmed'],
      });
      // queryClient.invalidateQueries({
      //   queryKey: ['getListDocument'],
      // });

      setIsOtherSelected(false);
      setIsModalOpen(false);
    },
    onError: (err) => {
      // setIsLoading(false);
      message.error(err.message);
    },
  });

  const handleOk = () => {
    // Validate the form fields
    form
      .validateFields()
      .then(() => {
        // If the form is in edit mode (dataEdit is true)
        if (dataEdit) {
          showConfirm(); // Show the confirmation dialog
        } else {
          form.submit(); // If not edit mode, submit the form
        }
      })
      .catch((errorInfo) => {
        // If form validation fails, return and handle the error
        console.error('Validation Failed:', errorInfo);
        return;
      });
    // setIsModalOpen(setIsModalOpen)
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsOtherSelected(false);
    form.resetFields();
    // queryClient.invalidateQueries({
    //   queryKey: ['getListDocument'],
    // });
  };

  const onFinish = async (dataForm: IDependencyCreate) => {
    // Transform the date format

    if (!project_id) return;
    const formattedData = {
      ...dataForm,
      project_id: project_id,
      is_no_deadline: isNoDeadline,
      deadline: dataForm.deadline
        ? dayjs(dataForm.deadline).format('YYYY/MM/DD')
        : '',
    };

    if (dataEdit) {
      updateDependency.mutate({
        project_id: project_id,
        id: dataEdit.dependency_id,
        data: formattedData,
      });
    } else {
      createDependency.mutate({
        project_id: project_id,
        data: formattedData,
      });
    }
  };

  const handleDataSourceChange = (value: string) => {
    setIsOtherSelected(value === 'other');
  };

  useEffect(() => {
    if (dataEdit) {
      // Convert date string to Dayjs object or set to null if invalid
      const dateValue = dataEdit.deadline
        ? dayjs(dataEdit.deadline, 'YYYY/MM/DD')
        : null;

      // Check if the dateValue is valid
      const isValidDate = dateValue?.isValid();

      setIsNoDeadline(dataEdit.is_no_deadline);

      // Prepare the initial form values
      const editValues: any = {
        dependency_name: dataEdit.dependency_name,
        type: dataEdit.type.toLocaleLowerCase(),
        deadline: isValidDate ? dateValue : null,
        data_source: dataEdit.data_source,
        // Third_Party: dataEdit.Third_Party,
        is_no_deadline: dataEdit.is_no_deadline,
        dependency_detail: dataEdit.dependency_detail,
        project_id: dataEdit.project_id,
        is_confirmed: dataEdit.is_confirmed ? dataEdit.is_confirmed : false,
      };

      // If data_source is "other", set the other_text field
      if (dataEdit.data_source === 'other') {
        setIsOtherSelected(true);
        editValues.other_text = dataEdit.other_text || '';
      }

      // Set the form fields
      form.setFieldsValue(editValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEdit]);

  useEffect(() => {
    const defaultData = [{ value: 'other', label: 'Other' }];
    const dataWithOther = dataListDocument
      ? [...dataListDocument, ...defaultData]
      : defaultData;
    setDataSources(dataWithOther);
    // eslint-disable-next-line
  }, [dataListDocument]);

  useEffect(() => {
    if (project_id && isModalOpen) {
      refetchListDocument();
    }
    // eslint-disable-next-line
  }, [project_id, isModalOpen]);

  const showConfirm = () => {
    confirm({
      title: 'Confirm the action',
      // icon: m,
      content: 'Are you sure you would like to edit the dependency?',
      onOk() {
        form.submit();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const onChangeNoDeadline = (e: CheckboxChangeEvent) => {
    setIsNoDeadline(e.target.checked);
    form.setFieldsValue({ deadline: null });
  };

  return (
    <Modal
      title={dataEdit ? `Edit Dependency` : `Add New Dependency`}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={720}
      okText={dataEdit ? `Edit Dependency` : `Add New Dependency`}
    >
      {contextHolder}
      <Form
        {...layout}
        form={form}
        onFinish={onFinish}
        initialValues={defaultValues}
      >
        <Form.Item
          name="dependency_name"
          label="Dependency Name"
          rules={[{ required: true, message: 'Please input Dependency Name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="dependency_detail" label="Details">
          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
        </Form.Item>
        <Form.Item name="type" label="Type">
          <Radio.Group>
            <Radio value="internal"> Internal </Radio>
            <Radio value="external"> External </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="is_no_deadline" label="No Deadline">
          <Checkbox checked={isNoDeadline} onChange={onChangeNoDeadline} />
        </Form.Item>
        {!isNoDeadline && (
          <Form.Item
            name="deadline"
            label="Deadline"
            rules={[
              {
                required: true,
                message: 'Please input the deadline in the format YYYY/MM/DD',
              },
            ]}
          >
            <DatePicker
              style={{ width: '100%' }}
              format={'YYYY/MM/DD'}
              disabledDate={disabledDate}
            />
          </Form.Item>
        )}

        <Form.Item
          name="data_source"
          label="Data Source"
          rules={[{ required: true, message: 'Please input Data Source!' }]}
        >
          <Select onChange={handleDataSourceChange}>
            {dataSources.map((source) => (
              <Option key={source.value} value={source.value}>
                {source.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {isOtherSelected && (
          <Form.Item
            name="other_text"
            label="Other"
            rules={[{ required: true, message: 'Please input Data Source!' }]}
          >
            <Input />
          </Form.Item>
        )}
        {/* <Form.Item name="Third_Party" label="Third Party">
          <Input />
        </Form.Item> */}
        <Form.Item name="is_confirmed" label="Confirm">
          <Radio.Group>
            <Radio value={true}> Yes </Radio>
            <Radio value={false}> No </Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};
